import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as FacebookIcon } from '@icons/facebook.svg'
import { ReactComponent as TwitterIcon } from '@icons/twitter.svg'
import { ReactComponent as InstagramIcon } from '@icons/instagram.svg'
import CircleLottie from '@svgs/CircleLottie'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import { ThemeContext } from '@layout/Layout'
import { useSocial } from '@hooks/useSocial'
import { motion } from 'framer-motion'

export const Social = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  ${styledMap('layout', {
    vert: css`
      ${media.greaterThan('l')`
        flex-flow: column;
        justify-content: flex-end;
      `}
    `,
    default: '',
  })};

  li {
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-left: ${spacing(2)};
    ${styledMap('layout', {
      vert: css`
        ${media.greaterThan('l')`
          margin-top: ${spacing(2)};
          margin-left: 0;
        `};
      `,
      default: '',
    })};
    width: ${spacing(7.5)};
    height: ${spacing(7.5)};
    list-style: none;

    ${media.greaterThan('s')`
      height: ${spacing(8.5)};
      width: ${spacing(8.5)};
    `}

    &:first-child {
      margin-left: 0;
      ${styledMap('layout', {
        vert: css`
          margin-top: 0;
        `,
        default: ``,
      })};
    }
  }

  a {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    transform: scale(1);
    transition: transform ${tokens.get('transition.snappy')};
  }

  .icon {
    display: block;
    height: ${spacing(3)};
    width: ${spacing(3)};
    fill: ${styledMap('theme', {
      dark: tokens.color('text', 'light'),
      default: tokens.color('text'),
    })};
  }

  .circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const socialShare = () => {
  if (typeof window === 'undefined') return {}
  return {
    facebook: `https://www.facebook.com/sharer.php?u=${window.location.href}`,
    twitter: `https://twitter.com/intent/tweet?url=${window.location.href}`,
  }
}

const SocialIcon = ({ platform, className }) => {
  switch (platform) {
    case 'facebook':
      return <FacebookIcon className={className} />
    case 'twitter':
      return <TwitterIcon className={className} />
    case 'instagram':
      return <InstagramIcon className={className} />
    default:
      return null
  }
}

export const SocialLink = ({ social, platform, index, theme, isShare }) => {
  const [isHovered, setIsHovered] = useState(false)

  const openWindow = (url) => {
    if (typeof window === 'undefined') return false
    const left = (window.width - 570) / 2
    const top = (window.height - 570) / 2
    const params = [
      'menubar=no',
      'toolbar=no',
      'status=no',
      'width=570',
      'height=570',
      `top=${top}`,
      `left=${left}`,
    ].join(',')
    window.open(url, 'NewWindow', params)
  }

  const href = isShare
    ? {
        onClick: (e) => {
          e.preventDefault()
          openWindow(social[platform])
        },
        href: '#',
      }
    : {
        href: social[platform],
        target: '_blank',
      }

  return (
    <motion.a
      {...href}
      rel="noopener noreferrer"
      onHoverStart={() => {
        if (!isHovered) {
          setIsHovered(true)
        }
      }}
      onHoverEnd={() => {
        if (isHovered) {
          setIsHovered(false)
        }
      }}
    >
      <CircleLottie
        circleIndex={index}
        className="circle"
        theme={theme}
        isHovered={isHovered}
      />
      <SocialIcon platform={platform} className="icon" />
    </motion.a>
  )
}

export default ({ className, layout, theme: themeOveride, isShare }) => {
  const theme = themeOveride || useContext(ThemeContext)?.theme

  const social = isShare ? socialShare() : useSocial()
  return (
    <Social className={className} theme={theme} layout={layout}>
      {social &&
        Object.keys(social).map((platform, index) => (
          <li key={platform}>
            <SocialLink
              social={social}
              platform={platform}
              index={index}
              theme={theme}
              isShare={isShare}
            />
          </li>
        ))}
    </Social>
  )
}
