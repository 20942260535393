import React from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { InputBase } from '@mixins/MixinsForms'
import { tokens, spacing, box, media } from '@tokens'
import FieldLabel from './FieldLabel'
import { useFormContext } from 'react-hook-form'

export const Input = styled.input`
  ${InputBase}
`

export default ({
  name,
  type,
  isRequired,
  errorMessage,
  handleFocus,
  handleBlur,
  isActive,
  label,
  theme,
}) => {
  const {
    register,
    errors: { [name]: errors },
  } = useFormContext()

  const validation = {
    required: errorMessage || 'This Field is required',
  }
  return (
    <>
      <FieldLabel
        inputID={name}
        label={label}
        isRequired={isRequired}
        type={type}
        isActive={isActive}
      />
      {errors && <span className="error">{errors?.types?.required}</span>}
      <Input
        name={name}
        type={type}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={register(isRequired ? validation : {})}
        theme={theme}
      />
    </>
  )
}
