import React, { useEffect, useState, useRef } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { motion, AnimatePresence } from 'framer-motion'
import NavDrawerTrigger from '@regions/NavDrawer/NavDrawerTrigger'
import NavDrawer from '@regions/NavDrawer/'
import NavBar from '@regions/NavBar'
import { Waypoint } from 'react-waypoint'
import useEventListener from '@hooks/useEventListener'
import { keys } from '@utils/constants'
import HeaderInner from './HeaderInner'
import BackButton from './BackButton'

export const Header = styled.header`
  position: absolute;
  top: ${spacing()};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: ${tokens.get('maxWidth.vast')};
  z-index: ${tokens.z('header')};
  box-sizing: border-box;
  padding-left: ${spacing(2)};
  padding-right: ${spacing(2)};
  height: ${spacing(10)};

  ${media.greaterThan('s')`
    top: ${spacing(2)};
  `}

  ${media.greaterThan('m')`
    padding-left: ${spacing(4)};
    padding-right: ${spacing(4)};
  `}
`

export default ({ activePath, navBarActivePath, theme }) => {
  const [isNavOpen, setNavOpen] = useState(false)
  const [isSticky, setSticky] = useState(false)

  const ref = useRef(null)

  const toggleNav = (e) => {
    setNavOpen(!isNavOpen)
  }

  const closeNav = (e) => {
    setNavOpen(false)
  }

  const onEnter = (waypoint) => {
    setSticky(false)
  }

  const onLeave = (waypoint) => {
    setSticky(true)
  }

  useEffect(() => {
    if (isNavOpen) {
      setNavOpen(false)
    }
  }, [activePath])

  useEventListener('keydown', (event) => {
    if (keys.ESC === event.keyCode) {
      closeNav()
    }
  })

  return (
    <>
      <Header key="header" className="header">
        <Waypoint key="header-waypoint" onEnter={onEnter} onLeave={onLeave} />
        <HeaderInner key="header-inner">
          <AnimatePresence>
            {navBarActivePath && (
              <BackButton navBarActivePath={navBarActivePath} theme={theme} />
            )}
          </AnimatePresence>
          <NavBar activePath={navBarActivePath || activePath} theme={theme} />
        </HeaderInner>
      </Header>
      <NavDrawerTrigger
        key="nav-drawer-trigger"
        ref={ref}
        onClick={toggleNav}
        isOpen={isNavOpen}
        isSticky={isSticky}
        theme={theme}
      />
      <NavDrawer
        key="nav-drawer"
        isNavOpen={isNavOpen}
        activePath={activePath}
      />
    </>
  )
}
