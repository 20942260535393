import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import { HugeTypeStyles, LinkTextStyles } from '@styles/Typography'
import ArrowCircle from '@svgs/ArrowCircle'
import ArrowCircleLottie from '@svgs/ArrowCircleLottie'
import ArrowCircleLottieLine from '@svgs/ArrowCircleLottieLine'
import { motion } from 'framer-motion'

export const CallToActionLinkText = styled.div`
  .link,
  .date {
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    margin-top: auto;
    width: calc(100% + ${spacing(4)});
    height: ${spacing(8)};
  }

  .date {
    ${HugeTypeStyles}
    font-weight: ${tokens.get('type.fontWeight.normal')};
  }

  .link {
    ${LinkTextStyles}
    font-size: 1rem;

    .link-text {
      white-space: nowrap;
      margin-right: ${spacing(1)};
    }
  }
`

export default ({
  date,
  linkText = 'Read More',
  className,
  isHovered,
  theme,
}) => {

  return (
    <CallToActionLinkText className={className}>
      {date ? (
        <span className="date">
          {date}
          <ArrowCircleLottie
            className="svg"
            isHovered={isHovered}
            theme={theme}
          />
        </span>
      ) : (
        <span className="link">
          {linkText && <span className="link-text">{linkText}</span>}
          <ArrowCircleLottieLine
            className="svg"
            isHovered={isHovered}
            theme={theme}
          />
        </span>
      )}
    </CallToActionLinkText>
  )
}
