// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-templates-beer-js": () => import("./../../../src/templates/beer.js" /* webpackChunkName: "component---src-templates-beer-js" */),
  "component---src-templates-beers-js": () => import("./../../../src/templates/beers.js" /* webpackChunkName: "component---src-templates-beers-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-community-js": () => import("./../../../src/templates/community.js" /* webpackChunkName: "component---src-templates-community-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-flexible-content-js": () => import("./../../../src/templates/flexibleContent.js" /* webpackChunkName: "component---src-templates-flexible-content-js" */),
  "component---src-templates-history-js": () => import("./../../../src/templates/history.js" /* webpackChunkName: "component---src-templates-history-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-job-post-js": () => import("./../../../src/templates/jobPost.js" /* webpackChunkName: "component---src-templates-job-post-js" */),
  "component---src-templates-mixer-pack-js": () => import("./../../../src/templates/mixer-pack.js" /* webpackChunkName: "component---src-templates-mixer-pack-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */)
}

