import React from 'react'
import styled from 'styled-components'
import { tokens, media, spacing, box } from '@tokens'

export const HeaderInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${spacing(2)};
  padding-left: ${spacing(2)};
  padding-right: ${spacing(8)};
`

export default ({ children }) => {
  return <HeaderInner>{children}</HeaderInner>
}
