const colors = {
  white: {
    base: 'rgba(255, 255, 255, 0.9)',
    '09': 'rgba(255, 255, 255, 0.9)',
    '08': 'rgba(255, 255, 255, 0.8)',
    '07': 'rgba(255, 255, 255, 0.7)',
    '06': 'rgba(255, 255, 255, 0.6)',
    '05': 'rgba(255, 255, 255, 0.5)',
    '04': 'rgba(255, 255, 255, 0.4)',
    '03': 'rgba(255, 255, 255, 0.3)',
    '02': 'rgba(255, 255, 255, 0.2)',
    '01': 'rgba(255, 255, 255, 0.1)',
    dark: '#f2f0ea',
  },
  black: {
    base: '#000',
    '09': 'rgba(0, 0, 0, 0.9)',
    '08': 'rgba(0, 0, 0, 0.8)',
    '07': 'rgba(0, 0, 0, 0.7)',
    '06': 'rgba(0, 0, 0, 0.6)',
    '05': 'rgba(0, 0, 0, 0.5)',
    '04': 'rgba(0, 0, 0, 0.4)',
    '03': 'rgba(0, 0, 0, 0.3)',
    '02': 'rgba(0, 0, 0, 0.2)',
    '01': 'rgba(0, 0, 0, 0.1)',
    text: '#171716',
  },
  red: {
    base: '#e64d2e',
    light: '#ffa6a6',
  },
  teal: {
    base: '#46dcd0',
    light: '#70f0e6',
  },
  blue: {
    base: '#a3b6bf',
    dark: '#8fa6b3',
  },
  yellow: {
    base: '#fec10d',
    dark: '#f2b500',
  },
  green: {
    base: '#82b33f',
    dark: '#75a632',
  },
  pink: {
    base: '#ff8383',
    lighter: '#ffa6a6',
  },
  grey: {
    base: '#4a4a4a',
    lightish: '#808080',
    light: '#b3b0ab',
    lighter: '#e6e6e6',
    dark: '#303030',
    darker: '#181d25',
  },
}

const brand = {
  light: colors.yellow.base,
  dark: colors.green.base,
}

const button = {
  base: {
    light: {
      text: '#f2f0ea',
      fainter: 'rgba(0, 0, 0, 0.6)',
      background: 'rgba(0, 0, 0, 0.08)',
      backgroundHover: 'rgba(0, 0, 0, 0.13)',
    },
    dark: {
      text: colors.black.base,
      fainter: 'rgba(242, 240, 234, 0.06)',
      background: 'rgba(255, 255, 255, 0.08)',
      backgroundHover: 'rgba(255, 255, 255, 0.13)',
    },
  },
}

export default {
  colorPalette: {
    ...colors,
    button,
    text: {
      base: colors.black.text,
      //light: 'rgba(242, 240,234, 0.9)',
      light: colors.white.dark,
      lightFainter: 'rgba(0, 0, 0, 0.6)',
      darkFainter: 'rgba(242, 240, 234, 0.6)',
    },
    border: {
      base: 'rgba(0, 0, 0, 0.3)',
    },
    link: {
      base: brand.dark,
      hover: brand.light,
      focus: brand.light,
      active: brand.light,
    },
    teaser: {
      grey: colors.grey.light,
      yellow: colors.yellow.base,
      green: colors.green.base,
      blue: colors.blue.base,
      hover: {
        grey: button.base.light.backgroundHover,
        yellow: colors.yellow.dark,
        green: colors.green.dark,
        blue: colors.blue.dark,
      },
    },
    filter: {
      grey: {
        base: 'rgba(153, 153, 153, 0.5)',
        light: 'rgba(0, 0, 0, 0.08)',
      },
      border: '#979797',
      toggle: 'rgba(0, 0, 0, 0.9)',
    },
    gradient: {
      backgrounds: {
        light: {
          left: '#f2f1eb',
          right: '#d9d7d1',
        },
        dark: {
          left: '#292929',
          right: '#1F1F1F',
        },
      },
      hero: {
        light: {
          from: 'rgba(242, 240, 234, 0.75)',
          to: 'rgba(242, 240, 234, 0)',
        },
        dark: {
          from: 'rgba(61, 77, 61, 0.75)',
          to: 'rgba(61, 77, 61, 0)',
        },
      },
      malt: {
        left: '#ebd581',
        right: '#471415',
      },
      hops: {
        left: '#c1de9b',
        right: '#5e8f43',
      },
    },
    nav: {
      background: '#1f1f1f',
      text: '#f2f0ea',
    },
    home: {
      hero: 'rgb(31, 31, 31)',
    },
    footer: {
      background: '#1f1f1f',
      text: '#f2f0ea',
    },
    success: {
      base: '#7eb336',
    },
    error: {
      base: '#e64d2e',
    },
  },
  brand: {
    ...brand,
  },
}
