import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'

export const ComponentContainerBase = css`
  ${box({
    spacing: 'x',
  })};
  position: relative;
  width: 100%;

  ${styledMap('isFlex', {
    true: css`
      ${box({
        spacing: 'y',
        multiplier: 0.5,
        type: 'margin',
      })};
    `,
    default: css`
      ${box({
        spacing: 'y',
        type: 'margin',
      })};
    `,
  })};

  ${styledMap('collapse', {
    true: css`
      ${box({
        spacing: 'y',
        multiplier: 0,
        type: 'margin',
      })};
    `,
    default: '',
  })};
`

export const ComponentInnerBase = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${media.greaterThan('s')`
    max-width: ${tokens.get('maxWidth.narrow')};
  `}

  ${media.greaterThan('l')`
    max-width: ${tokens.get('maxWidth.base')};
  `}

  ${styledMap('size', {
    narrow: css`
      ${media.greaterThan('s')`
        max-width: ${tokens.get('maxWidth.narrow')};
      `}
    `,
    base: css`
      ${media.greaterThan('m')`
        max-width: ${tokens.get('maxWidth.base')};
      `}
    `,
    wide: css`
      ${media.greaterThan('m')`
        max-width: ${tokens.get('maxWidth.wide')};
      `}
    `,
    xwide: css`
      ${media.greaterThan('m')`
        max-width: ${tokens.get('maxWidth.xwide')};
      `}
    `,
    full: css`
      ${media.greaterThan('s')`
        max-width: none ;
      `}
    `,
    default: css`
      ${media.greaterThan('xl')`
        max-width: ${tokens.get('maxWidth.wide')};
      `}
    `,
  })};
`

export const ComponentContentBase = css`
  max-width: ${tokens.get('maxWidth.narrow')};

  ${styledMap('contentAlignment', {
    left: css`
      margin-left: 0;
      margin-right: auto;
    `,
    right: css`
      margin-left: auto;
      margin-right: 0;
    `,
    default: css`
      margin-left: auto;
      margin-right: auto;
    `,
  })};
`

export const ComponentBase = css`
  ${ComponentContainerBase}

  > .inner {
    ${ComponentInnerBase}

    > .content {
      ${ComponentContentBase}
    }
  }
`
