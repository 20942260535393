import React, { useContext } from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ThemeContext } from '@layout/Layout'

const Svg = styled.svg`
  position: relative;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  fill-opacity: 0.9;
  fill-rule: 'evenodd';
  fill: ${styledMap('theme', {
    dark: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};

  ${media.greaterThan('s')`
      width: 1.5rem;
      height: 1.5rem;
  `}
`

const ArrowDual = ({ className, theme: themeOverride }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      theme={themeOverride || theme}
    >
      <path d="M14.21 11.994l-.794.81c-1.465 1.247-1.341 1.5-2.806 2.747-1.21 1.346-1.783 1.346-2.761 2.302-.094 1.912 3.022.843 2.73 2.734-.263 1.712-2.039.389-3.662.904-1.625.514-1.9-.018-3.358.876-1.574.966-1.833 2.38-3.043 1.159-1.21-1.222.023-1.696.51-3.358.49-1.667.855-1.556 1.344-3.221.489-1.666-.63-2.7 1.052-2.985 1.86-.316 1.39 1.18 2.61 2.179.788-.706 1.01-1.253 2.222-2.753.438-.543.983-.99 1.536-1.382l.794-.81c1.465-1.247 1.341-1.5 2.806-2.747 1.21-1.346 1.783-1.346 2.761-2.302.094-1.912-3.022-.843-2.73-2.734.263-1.712 2.039-.389 3.662-.904 1.625-.514 1.9.018 3.358-.876 1.574-.966 1.833-2.38 3.043-1.159 1.21 1.222-.023 1.696-.51 3.358-.49 1.667-.855 1.556-1.344 3.221-.489 1.666.63 2.7-1.052 2.985-1.86.316-1.39-1.18-2.61-2.179-.788.706-1.01 1.253-2.222 2.753-.438.543-.983.99-1.536 1.382z" />
    </Svg>
  )
}

export default ArrowDual
