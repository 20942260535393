import React, { useState, useEffect, useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import { motion } from 'framer-motion'
import { getBoxSpacingForProperty } from '@tokens/spacing'
import Logo from '@blocks/Logo'

const Background = styled.div`
  position: relative;
  z-index: ${tokens.z('content')};
  display: flex;
  flex-flow: column;
  color: ${styledMap('theme', {
    dark: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};

  > svg {
    display: block;
    position: absolute;
    top: 0%;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .logo {
    position: absolute;
    left: 50%;
    z-index: ${tokens.z('header')};
    ${getBoxSpacingForProperty('y', {
      property: 'top',
      multiplier: 1,
    })};
    transform: translateX(-50%);
  }
`

export default ({ children, theme, triggerTheme, gradient, postType }) => {
  let from
  let to

  if (gradient) {
    from = gradient.from
    to = gradient.to
  } else {
    from = tokens.color(`gradient.backgrounds.${theme}.left`)
    to = tokens.color(`gradient.backgrounds.${theme}.right`)
  }

  return (
    <Background theme={theme} gradient={gradient} postType={postType}>
      <Logo className="logo" lightLogo={triggerTheme === 'dark'} />
      <svg>
        <defs>
          <linearGradient id="myGradient">
            <motion.stop
              offset="0%"
              stopColor={from}
              animate={{
                stopColor: from,
                transition: {
                  duration: 0.6,
                },
              }}
            />
            <motion.stop
              offset="100%"
              stopColor={to}
              animate={{
                stopColor: to,
                transition: {
                  duration: 0.6,
                },
              }}
            />
          </linearGradient>
        </defs>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="url('#myGradient')"
        />
      </svg>
      {children}
    </Background>
  )
}
