import React from 'react'
import styled from 'styled-components'
import { BaseType } from '@styles/Typography'

export const Subheading = styled.p`
    ${BaseType}
    opacity: 0.5;
    margin-top: -1rem;

    &:first-child: {
        margin-top:0;
    }
`

export default ({
  className = 'subheading',
  selectedFields = ['subheading'],
  subheading,
  children,
}) => {

  if (children) {
    return (
      <Subheading className={className}>
        {children}
      </Subheading>
    )
  }

  if (!subheading || !selectedFields.includes('subheading')) {
    return null
  }

  return (
    <Subheading
      className={className}
      dangerouslySetInnerHTML={{ __html: subheading }}
    />
  )
}
