import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import styled, { css } from 'styled-components'
import { spacing, media, box, tokens } from '@tokens'
import { motion } from 'framer-motion'

export const Loader = styled(motion.div)`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

export default ({ className = '', isLoading, color = 'grey', size = 24 }) => {
  return (
    <Loader
      className={className}
      initial="hidden"
      animate={isLoading ? 'visible' : 'hidden'}
      variants={{
        visible: {
          height: 'auto',
          transition: {
            delayChildren: 0.1,
            duration: 0.2,
          },
        },
        hidden: {
          height: 0,
        },
      }}
    >
      <motion.div
        variants={{
          visible: {
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          },
          hidden: {
            opacity: 0,
          },
        }}
      >
        <BeatLoader
          size={size} // or 150px
          color={tokens.color(color)}
          loading
        />
      </motion.div>
    </Loader>
  )
}
