// TODO Refactor with better solution for setting template Data
export const formatTemplateData = (data = {}) => {
  const { heroFields } = data
  const templateData = {
    ...data,
  }

  templateData.heroFields =
    heroFields && heroFields.textContent
      ? heroFields
      : {
          textContent: {},
        }

  return templateData
}

export const formatCategoriesData = (categories) => {
  const categoriesObject = {}

  categories.forEach((category) => {
    if (!category.wpParent) {
      // IF no posts in category
      if (category.posts.nodes.length < 1) {
        return
      }
      // TODO The color data isn't being pulled correctly fromWP
      // This I believe has to do with ACF field naming conventions
      // or a bug in the source resolvers.
      category.color = category.colorCategory
      categoriesObject[category.slug] = category
      categoriesObject[category.slug].subCategories = []
    } else {
      // If parent category exists
      if (category.wpParent.slug in categoriesObject) {
        categoriesObject[category.wpParent.slug].subCategories.push(category)
      }
    }
  })

  return Object.values(categoriesObject).map((categoriesObjectValue) => {
    return categoriesObjectValue
  })
}

// TODO standardize schemas
// There is the gatsby GraphQL schema and the WP GraphQL schema hence the
// need for the slight change in names the WP GraphQL schema is only used
// for the dyanmic filtering systems
export const formatPostData = (posts) => {
  return posts.map(
    ({
      id,
      title,
      eventDetails,
      contentPost,
      topCategory: { color = 'grey', name: category },
      uri,
      ...post
    }) => {
      const image = contentPost?.featuredImage
      // TODO This is temp, date format is expected to change.
      let date
      if (eventDetails && eventDetails.eventDate) {
        const dates = eventDetails.eventDate.split('/')
        date = `${dates[0]}/${dates[1]}`
      }

      return {
        id,
        title,
        uri,
        category,
        color,
        date,
        image,
      }
    }
  )
}

// https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
export const slugify = (string) => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}
