import { css } from 'styled-components'
import { media } from '@tokens/index'

export const spacingValues = {
  base: '1.5rem',
  padding: '1rem',
  component: {
    xxs: {
      x: 2,
      y: 15,
    },
    xs: {
      x: 4,
      y: 15,
    },
    s: {
      x: 10,
      y: 15,
    },
    m: {
      x: 12,
      y: 20,
    },
    l: {
      x: 12,
      y: 20,
    },
    xl: {
      x: 12,
      y: 20,
    },
  },
  step: 0.5,
  scale: {
    xs: '0.5rem',
    s: '2rem',
    m: '4rem',
    l: '6rem',
    xl: '8rem',
  },
}

export const getComponentSpacing = (direction, { query, multiplier } = {}) => {
  const spacing =
    spacingValues.component[query][direction] * multiplier * spacingValues.step

  return `${spacing}rem`
}

const boxInner = ({ spacing, type = 'padding', ...args } = {}) => {
  switch (spacing) {
    case 'x':
      return css`
        ${type}-left: ${getComponentSpacing('x', args)};
        ${type}-right: ${getComponentSpacing('x', args)};
      `
      break
    case 'left':
      return css`
        ${type}-left: ${getComponentSpacing('x', args)};
      `
      break
    case 'right':
      return css`
        ${type}-right: ${getComponentSpacing('x', args)};
      `
      break
    case 'y':
      return css`
        ${type}-top: ${getComponentSpacing('y', args)};
        ${type}-bottom: ${getComponentSpacing('y', args)};
      `
      break
    case 'top':
      return css`
        ${type}-top: ${getComponentSpacing('y', args)};
      `
      break
    case 'bottom':
      return css`
        ${type}-bottom: ${getComponentSpacing('y', args)};
      `
      break
    default:
      return css`
        ${type}: ${getComponentSpacing('y', args)}
          ${getComponentSpacing('x', args)};
      `
      break
  }
}

// Standard box spacing
export const box = ({
  spacing = 'all',
  multiplier = 1,
  type = 'padding',
} = {}) => {
  return css`
    display: block;

    ${boxInner({ spacing, query: 'xxs', multiplier, type })}

    ${media.greaterThan('xs')`
      ${boxInner({ spacing, query: 'xs', multiplier, type })}
    `}

    ${media.greaterThan('s')`
      ${boxInner({ spacing, query: 's', multiplier, type })}
    `}

    ${media.greaterThan('m')`
      ${boxInner({ spacing, query: 'm', multiplier, type })}
    `}

    ${media.greaterThan('l')`
      ${boxInner({ spacing, query: 'l', multiplier, type })}
    `}

    ${media.greaterThan('xl')`
      ${boxInner({ spacing, query: 'xl', multiplier, type })}
    `}
  `
}

export const getBoxSpacingForProperty = (
  direction,
  { multiplier, property } = {}
) => {
  return css`
    ${property}: ${getComponentSpacing(direction, {
    query: 'xxs',
    multiplier,
  })};

    ${media.greaterThan('xs')`
      ${property}: ${getComponentSpacing(direction, {
      query: 'xs',
      multiplier,
    })};
    `}

    ${media.greaterThan('s')`
      ${property}: ${getComponentSpacing(direction, {
      query: 's',
      multiplier,
    })};
    `}

    ${media.greaterThan('m')`
      ${property}: ${getComponentSpacing(direction, {
      query: 'm',
      multiplier,
    })};
    `}

    ${media.greaterThan('l')`
      ${property}: ${getComponentSpacing(direction, {
      query: 'l',
      multiplier,
    })};
    `}
  `
}

export const spacing = (multiple = 1) => {
  return `${spacingValues.step * multiple}rem`
}

export const compBox = () => {
  return css`
    ${box({
      spacing: 'x',
    })};
    ${box({
      spacing: 'y',
      type: 'margin',
    })};
  `
}
