import React, { useState } from 'react'
import useEventListener from '@hooks/useEventListener'
import { media } from '@tokens'

export const useBreakpoint = () => {
  if (typeof window === 'undefined') return

  const [breakpoints] = useState(
    Object.keys(media.to).reduce(
      (object, key, index) => ({ ...object, [key]: index }),
      {}
    )
  )

  const getCurrent = () => {
    return window
      .getComputedStyle(document.querySelector(':root'))
      .getPropertyValue('--breakpoint')
      .trim()
  }

  const [current, setCurrent] = useState(getCurrent())

  const updateCurrent = () => {
    setCurrent(getCurrent())
  }

  const emitEvent = newBreakpoint => {
    window.dispatchEvent(
      new CustomEvent('breakpoint', {
        detail: {
          old: current,
          new: newBreakpoint,
          direction:
            breakpoints[current] > breakpoints[newBreakpoint]
              ? 'smaller'
              : 'larger',
        },
      })
    )
    updateCurrent()
  }

  const changeBreakpoint = () => {
    if (current !== getCurrent()) {
      emitEvent(getCurrent())
    }
  }

  const is = target => {
    return current === target
  }

  const min = target => {
    return breakpoints[current] >= breakpoints[target]
  }

  const max = target => {
    return breakpoints[current] <= breakpoints[target]
  }

  useEventListener('resize', () => {
    changeBreakpoint()
  })

  return {
    current,
    breakpoints,
    is,
    min,
    max,
    getCurrent,
  }
}

export default useBreakpoint
