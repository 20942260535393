import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'
import { ListItemBase, ListBullets } from '@mixins/MixinsList'

export const TextUnderLine = css`
  position: relative;
  text-decoration: underline;
  ${'' /* Only supports firefox */}
  text-decoration-thickness: 1px;

  &:hover {
    color: ${tokens.color('red')};
  }
`

export const ContentMaxWidth = css`
  max-width: ${styledMap('size', {
    vast: tokens.get('maxWidth.vast'),
    xwide: tokens.get('maxWidth.xwide'),
    wide: tokens.get('maxWidth.wide'),
    base: tokens.get('maxWidth.base'),
    tiny: tokens.get('maxWidth.tiny'),
    default: tokens.get('maxWidth.narrow'),
  })};
`

export const PageContentRTE = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: ${spacing(6)};

    &:first-child {
      margin-top: 0;
    }

    ${media.greaterThan('m')`
      margin-top: ${spacing(8)};
    `}
  }

  ul {
    margin-left: 0;
    margin-top: ${spacing(3)};
  }

  li {
    ${ListItemBase}
  }

  ul > li {
    ${ListBullets}
  }

  a {
    ${TextUnderLine}
  }

  .alignnone {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    width: unset !important;
    height: auto;
  }

  .aligncenter {
    display: block;
    margin: ${spacing(2)} auto;
    height: auto;
    max-width: 100%;
  }

  .alignleft,
  .alignright {
    margin-bottom: ${spacing(2)};
    height: auto;
  }

  @media (min-width: 30rem) {
    .alignleft {
      float: left;
      margin-right: ${spacing(2)};
    }

    .alignright {
      float: right;
      margin-left: ${spacing(2)};
    }
  }
`

export const TextColorStyles = css`
  color: ${styledMap('theme', {
    light: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};
`
