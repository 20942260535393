import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'

export const ListBase = css`
  list-style: none;
  margin-left: 0;

  > li {
    margin-left: 0;
  }
`

export const ListInlineFlex = css`
  display: inline-flex;
  margin-left: 0;
  list-style: none;
`

export const ListBullets = css`
  position: relative;
  list-style: none;

  ${media.lessThan('s')`
    margin-left: 1rem;
  `}

  &::before {
    position: absolute;
    left: -1rem;
    top: 0.625em;
    content: '';
    display: block;
    line-height: 1;
    background-color: ${styledMap('theme', {
      dark: tokens.color('white', '04'),
      default: tokens.color('black', '04'),
    })};
    width: 0.325rem;
    height: 0.325rem;
    border-radius: 100%;
  }
`

export const ListItemBase = css`
  margin-top: ${spacing()};
  line-height: ${tokens.get('type.lineHeight.list')};

  &:first-child {
    margin-top: 0;
  }
`
