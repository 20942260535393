  import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { BlogH1Sizes, LargeTypeSizes } from '@styles/Typography'
import PropTypes from 'prop-types'
import { ContentMaxWidth } from '@mixins/MixinsPage'
import TextContent from '@elements/TextContent'
import Heading from '@elements/Heading'
import Subheading from '@elements/Subheading'
import Links from '@blocks/Links'
import CallToActionLink from '@elements/CallToActionLink'

export const ComponentContent = styled.div`
  ${ContentMaxWidth}
  position: relative;
  width: 100%;
  margin: ${styledMap('contentAlign', {
    center: '0 auto',
    right: '0 0 0 auto',
    left: '0 auto 0 0',
    default: '0 auto',
  })};
  text-align: ${styledMap('textAlign', {
    center: 'center',
    right: 'right',
    default: 'left',
  })};

  &:not(:last-child) {
    margin-bottom: ${spacing(4)};

    ${media.greaterThan('s')`
      margin-bottom: ${spacing(6)};
    `}
  }

  ${styledMap('isIntro', {
    true: css`
      max-width: ${tokens.get('maxWidth.base')};

      ${media.greaterThan('l')`
        margin-right: 0;
      `}
    `,
    default: '',
  })};

  ${styledMap('designations', {
    isSingleBeerPage: css`
      padding-left: 16.25rem;
    `,
    default: '',
  })};
`

export default ({
  className,
  children,
  textContent: { selectedFields, heading, subheading, content, link } = {},
  hasArrow = true,
  size,
  isIntro,
  textSize,
  hasCTA,
  headingSize,
  textAlign,
  contentAlign,
}) => {
  if (children) {
    return (
      <ComponentContent
        size={size}
        className={className}
        isIntro={isIntro}
        headingSize={headingSize}
        contentAlign={contentAlign}
        textAlign={textAlign}
      >
        {children}
      </ComponentContent>
    )
  }

  if (!selectedFields) {
    return null
  }

  return (
    <ComponentContent
      isIntro={isIntro}
      size={size}
      className={className}
      contentAlign={contentAlign}
      textAlign={textAlign}
    >
      <Heading
        heading={heading}
        selectedFields={selectedFields}
        headingSize={headingSize}
      />
      <Subheading
        subheading={subheading}
        selectedFields={selectedFields}
      />
      <TextContent
        content={content}
        selectedFields={selectedFields}
        contentAlign={contentAlign}
        textSize={textSize || isIntro ? 'large' : ''}
        size={size}
        isIntro={isIntro}
      />
      {hasCTA ? (
        <CallToActionLink link={link} />
      ) : (
        <Links
          link={link}
          selectedFields={selectedFields}
          hasArrow={hasArrow}
        />
      )}
    </ComponentContent>
  )
}

ComponentContent.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
}
