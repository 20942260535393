import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { BaseType, BaseTypeSizes, LargeTypeSizes } from '@styles/Typography'
import { ThemeContext } from '@layout/Layout'
import { ContentMaxWidth, PageContentRTE } from '@mixins/MixinsPage'

export const TextContent = styled.div`
  ${ContentMaxWidth}
  ${BaseType}
  ${PageContentRTE}
  width: 100%;

  ${styledMap('textSize', {
    large: LargeTypeSizes,
    default: BaseTypeSizes,
  })};

  ${styledMap('contentAlign', {
    left: css`
      margin-left: 0;
      margin-right: auto;
    `,
    right: css`
      margin-right: 0;
      margin-left: auto;
    `,
    default: css`
      margin-left: auto;
      margin-right: auto;
    `,
  })};

  ${styledMap('isIntro', {
    true: css`
      ${media.greaterThan('l')`
        max-width: none;
      `}
    `,
    default: '',
  })};
`
export default ({
  children,
  className = 'summary',
  size = 'narrow',
  isIntro,
  content,
  textSize,
  selectedFields = ['content'],
  contentAlign,
  theme: themeOverride,
}) => {
  const { theme } = useContext(ThemeContext)
  if (children) {
    return (
      <TextContent
        isIntro={isIntro}
        className={className}
        textSize={textSize}
        size={size}
        contentAlign={contentAlign}
        theme={themeOverride || theme}
      >
        {children}
      </TextContent>
    )
  }

  if (!content || !selectedFields.includes('content')) {
    return null
  }

  return (
    <TextContent
      isIntro={isIntro}
      className={className}
      size={size}
      textSize={textSize}
      contentAlign={contentAlign}
      dangerouslySetInnerHTML={{ __html: content }}
      theme={themeOverride || theme}
    />
  )
}
