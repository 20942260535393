import React from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'

const Svg = styled.svg`
  position: relative;
  display: block;
  width: 1.25rem;
  fill: ${styledMap('theme', {
    dark: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};

  ${media.greaterThan('s')`
    width: 1.5rem;
  `}
`

const Arrow = ({ className, theme }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 13"
      className={className}
      theme={theme}
    >
      <path
        d="M23.096 9.005c-1.85.998-2.074.585-3.924 1.583s-1.758 2.857-3.469 1.634C13.811 10.87 15.5 10 15.29 8.084 14 8 13.34 8.274 11 8.5c-2.341.227-3.5 0-4.939.07-1.439.072-3.217 2.58-4.873.912C-.635 7.642-.315 6.348 1.5 4.5H6c2.343.21 2.452-.114 4.795.097C13 4.5 13.5 5 15.17 5.036c1.716-1.552-1.914-3.355-.043-4.717 1.693-1.234 2.11 1.445 3.965 2.42 1.857.977 1.641 1.67 3.677 2.178 2.198.546 3.633-.437 3.643 1.662.011 2.1-1.469 1.43-3.316 2.426z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default Arrow
