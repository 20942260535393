import React, { useCallback } from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { InputBase } from '@mixins/MixinsForms'
import { tokens, spacing, box, media } from '@tokens'
import { FieldLabel, LabelFontSizes } from './FieldLabel'
import { PlaceholderBase } from '@mixins/MixinsForms'
import { useFormContext } from 'react-hook-form'
import { useBreakpoint } from '@hooks/useBreakpoint'

export const Radio = styled.input`
  display: none;

  + label {
    ${PlaceholderBase}
    flex: 0 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-right: ${spacing(4)};
    line-height: 1;
    cursor: pointer;

    .label-text {
      opacity: 0.6;
      transition: opacity 200ms linear;
    }

    .circle {
      position: relative;
      width: ${spacing(3)};
      height: ${spacing(3)};
      margin-right: ${spacing(1)};
      border-radius: 100%;
      background-color: transparent;
      border: 3px solid
        ${styledMap('theme', {
          dark: tokens.color('text', 'darkFainter'),
          default: tokens.color('text', 'lightFainter'),
        })};
      transition: background 200ms linear;

      &::before {
        position: absolute;
        z-index: ${tokens.z('content')};
        top: 50%;
        left: 50%;
        content: '';
        display: block;
        margin-left: ${spacing(-0.5)};
        margin-top: ${spacing(-0.5)};
        width: ${spacing()};
        height: ${spacing()};
        border-radius: 100%;
        background: ${styledMap('theme', {
          light: tokens.color('text', 'light'),
          default: tokens.color('text'),
        })};
        transform: scale(0);
        transform-origin: center center;
        transition: transform 200ms linear;
      }
    }
  }

  &:checked + label {
    .label-text {
      opacity: 1;
    }

    .circle {
      background: ${styledMap('theme', {
        dark: tokens.color('text', 'light'),
        default: tokens.color('text'),
      })};

      &::before {
        transform: scale(1);
      }
    }
  }
`

const RadioField = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${spacing()};

  .label {
    margin-bottom: ${spacing(1)};

    ${media.lessThan('740px')`
      top: ${spacing(-0.5)};
    `}
  }

  .radio-field {
    flex: 0 1 auto;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
`

export default ({
  name,
  type,
  isRequired,
  defaultValue,
  label,
  errorMessage,
  choices,
  theme,
}) => {
  const {
    register,
    errors: { [name]: errors },
  } = useFormContext()
  const validation = {
    required: errorMessage || 'This field is required',
  }
  const Breakpoint = useCallback(useBreakpoint(), [])
  const fontSize =
    LabelFontSizes[
      Breakpoint && Breakpoint.max('s') ? Breakpoint.current : 'default'
    ]

  return (
    <RadioField theme={theme} fontSize={fontSize}>
      <FieldLabel
        inputID={name}
        className="label"
        style={{
          fontWeight: 400,
          letterSpacing: tokens.get('letterSpacing.tiny'),
          fontSize: fontSize.active,
          y: '-60%',
          left: 0,
        }}
      >
        {label}
        {isRequired && <span className="required">&nbsp;*</span>}
      </FieldLabel>
      {errors && <span className="error">{errors?.types?.required}</span>}

      {choices &&
        choices.map((input, index) => {
          return (
            <span key={index} className="radio-field">
              <Radio
                className="radio"
                id={`${name}-${input.text}`}
                name={name}
                type={type}
                value={input.value}
                ref={register(isRequired ? validation : {})}
                selected={input.isSelected}
                theme={theme}
              />
              <label className="radio-label" htmlFor={`${name}-${input.text}`}>
                <span className="circle" />
                <span className="label-text">{input.text}</span>
              </label>
            </span>
          )
        })}
    </RadioField>
  )
}
