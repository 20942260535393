import React from 'react'
import styled from 'styled-components'
import { spacing, media } from '@tokens'
import { Link } from '@elements/Link'

export const Links = styled.div`
  margin-top: ${spacing(6)};
`

export default ({
  children,
  link,
  selectedFields = ['link'],
  hasArrow,
  className = 'links',
}) => {
  if (children) {
    return <Links className={className}>{children}</Links>
  }

  if (!link || !selectedFields.includes('link')) {
    return null
  }

  return (
    <Links className={className} hasArrow={hasArrow}>
      <Link link={link} />
    </Links>
  )
}
