import React from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { InputBase } from '@mixins/MixinsForms'
import { tokens, spacing, box, media } from '@tokens'
import FieldLabel from './FieldLabel'
import { useFormContext } from 'react-hook-form'

export const Email = styled.input`
  ${InputBase}
`

export default ({
  name,
  type,
  isRequired,
  errorMessage,
  handleFocus,
  handleBlur,
  isActive,
  defaultValue,
  label,
  theme,
}) => {
  const {
    register,
    errors: { [name]: errors },
  } = useFormContext()
  const validation = {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Invalid email address',
    },
  }

  if (isRequired) {
    validation.required = errorMessage || 'Your Email is required'
  }

  return (
    <>
      <FieldLabel
        inputID={name}
        label={label}
        isRequired={isRequired}
        type={type}
        isActive={isActive}
        theme={theme}
      />
      {errors && <span className="error">{errors?.types?.required}</span>}
      <Email
        name={name}
        type="text"
        defaultValue={defaultValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={register(validation)}
        theme={theme}
      />
    </>
  )
}
