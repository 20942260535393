import React, { useContext } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import Arrow from '@svgs/Arrow'
import { Button, ExternalButton } from '@mixins/MixinsButton'

export const ExternalLink = ({ to, target, rel, children, className }) => {
  return (
    <a href={to} className={className} rel={rel} target={target}>
      {children}
    </a>
  )
}

export const Link = ({
  children,
  link,
  linkStyle = 'button',
  hasArrow = false,
  tabIndex = 0,
  className: classes,
  theme = 'light',
  isToggle,
}) => {
  const { file, type, text } = link
  const { url, title, target } = link.data ? link.data : link
  const className = `${isToggle ? 'button--toggle ' : ''}${classes}`
  const isInternal = /^\/(?!\/)/.test(url)
  const rel = !isInternal ? 'noopener noreferrer' : ''

  let StyledLink
  if (isInternal) {
    StyledLink = linkStyle === 'button' ? Button : GatsbyLink
  } else {
    StyledLink = linkStyle === 'button' ? ExternalButton : ExternalLink
  }

  if (type === 'download') {
    return (
      <StyledLink
        to={file.url.localFile.publicURL}
        tabIndex={tabIndex}
        download
        rel={rel}
        className={className}
        theme={theme}
        // isToggle={isToggle}
      >
        {children || text}
      </StyledLink>
    )
  }

  return (
    <StyledLink
      to={url}
      rel={rel}
      target={target}
      tabIndex={tabIndex}
      className={className}
      theme={theme}
      // isToggle={isToggle}
    >
      {children || title}
      {hasArrow ? <Arrow className="arrow" /> : ''}
    </StyledLink>
  )
}

export default Link
