import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { InputBase } from '@mixins/MixinsForms'
import { tokens, spacing, box, media } from '@tokens'
import Select from 'react-select'
import { useFormContext } from 'react-hook-form'
import FieldLabel from './FieldLabel'
import ArrowDown from '@svgs/ArrowDown'

export const SelectField = styled.div`
  .select-wrap {
    ${InputBase}
  }

  .value {
    position: absolute;
    top: 0;
    left: 0%;
    height: 100%;
    width: 100%;
    z-index: ${tokens.z('under')};
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: ${tokens.z('under')};
    margin-top: ${spacing(-1)};
    height: ${spacing(2)};
    width: ${spacing(2)};
    opacity: 0.6;
    /* margin-top: ${styledMap('isMenuOpen', {
      true: '0',
      default: spacing(-0.5),
    })}; */

    fill: ${styledMap('theme', {
      dark: tokens.color('text', 'light'),
      default: tokens.color('text'),
    })};
  }

  .file-name + .icon {
    opacity: 1;
  }
`

const selectStyles = {
  control: (provided) => {
    return {
      height: '100%',
    }
  },

  dropdownIndicator: (provided) => {
    return {
      display: 'none',
    }
  },
  placeholder: () => {
    return {
      display: 'none',
    }
  },
  singleValue: () => {
    return {
      display: 'none',
    }
  },
  container: (provided) => {
    return {
      height: '100%',
    }
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      height: '100%',
      padding: '0',
    }
  },
  option: (provided, state) => {
    const { isFocused, isSelected } = state
    return {
      ...provided,
      color: tokens.color('text'),
      backgroundColor:
        isFocused || isSelected
          ? tokens.color('black', '02')
          : provided.backgroundColor,
    }
  },
}

export default ({
  name,
  type,
  isRequired,
  handleFocus,
  handleBlur,
  errorMessage,
  isActive,
  label,
  defaultValue,
  choices,
  theme,
}) => {
  const {
    setValue,
    register,
    errors: { [name]: errors },
  } = useFormContext()
  const [selectValue, setSelectValue] = useState(defaultValue)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const validation = {
    required: errorMessage || 'This Field is required',
  }

  const handleChange = ({ label, value }) => {
    setSelectValue(value)
    setValue(name, value)
  }

  useEffect(() => {
    register({ name }, isRequired ? validation : {})
  }, [])

  useEffect(() => {
    handleBlur({
      target: {
        value: selectValue,
      },
    })
  }, [selectValue])

  return (
    <SelectField isMenuOpen={isMenuOpen} theme={theme}>
      <FieldLabel
        inputID={name}
        label={label}
        isRequired={isRequired}
        type={type}
        isActive={isActive}
      />
      {errors && <span className="error">{errors?.types?.required}</span>}
      <div className="select-wrap">
        <Select
          styles={selectStyles}
          id={name}
          name={name}
          options={choices.map(({ text: label, value }) => ({ label, value }))}
          onChange={handleChange}
          onMenuOpen={() => {
            handleFocus()
            setIsMenuOpen(true)
          }}
          onMenuClose={() => {
            setIsMenuOpen(false)
          }}
        />
        {selectValue && <span className="value">{selectValue}</span>}
        <ArrowDown className="icon" direction={isMenuOpen ? 'up' : 'down'} />
      </div>
    </SelectField>
  )
}
