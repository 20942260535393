import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import CircleLottie from '@svgs/CircleLottie'
import { tokens, media, spacing, box } from '@tokens'
import { ThemeContext } from '@layout/Layout'
import { motion } from 'framer-motion'
import ArrowDual from '@svgs/ArrowDual'

export const SvgContainer = styled(motion.span)`
  display: block;
  position: relative;

  .arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`

const ArrowDualCircle = ({ className, theme: themeOverride }) => {
  const { theme } = useContext(ThemeContext)
  const [isHovered, setIsHovered] = useState(false)

  return (
    <SvgContainer
      className={className}
      onHoverStart={() => {
        if (!isHovered) {
          setIsHovered(true)
        }
      }}
      onHoverEnd={() => {
        if (isHovered) {
          setIsHovered(false)
        }
      }}
    >
      <ArrowDual className="arrow" theme={theme} />
      <CircleLottie isHovered={isHovered} theme={themeOverride || theme} />
    </SvgContainer>
  )
}

export default ArrowDualCircle
