import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { LinkTextStyles } from '@styles/Typography'
import { tokens, spacing, media } from '@tokens'
import Link from '@elements/Link'
import NavLine from '@svgs/NavLine'
import { motion } from 'framer-motion'

export const NavBarItem = styled(motion.li)`
  position: relative;
  display: block;
  margin-right: ${spacing(4)};
  overflow: hidden;
  padding: ${spacing()} 0;

  a {
    ${LinkTextStyles};
    display: block;
    text-decoration: none;
    color: ${styledMap('theme', {
      dark: tokens.color('text', 'light'),
      light: tokens.color('text'),
    })};
    transition: color 300ms linear;
  }

  .nav-line {
    position: absolute;
    left: 0;
    width: 100%;
    fill: ${styledMap('theme', {
      dark: tokens.color('text', 'light'),
      light: tokens.color('text'),
    })};
  }
`

export default ({ className, link, theme, isActive }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <NavBarItem
      className={className}
      theme={theme}
      whileHover="hovered"
      onHoverStart={() => {
        if (!isHovered) {
          setIsHovered(true)
        }
      }}
      onHoverEnd={() => {
        if (isHovered) {
          setIsHovered(false)
        }
      }}
    >
      <Link
        link={{
          type: 'url',
          title: link.label,
          target: link.target,
          url: link.url,
        }}
        linkStyle="text"
      />
      <NavLine className="nav-line" isActive={isActive} isHovered={isHovered} />
    </NavBarItem>
  )
}
