import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ComponentBase } from '@mixins/MixinsComponent'

export const ComponentContainer = styled.div`
  ${ComponentBase}
`

export default ({ size, children, isFlex, collapse }) => {
  return (
    <ComponentContainer size={size} isFlex={isFlex} collapse={collapse}>
      <div className="inner">{children}</div>
    </ComponentContainer>
  )
}
