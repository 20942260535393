import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { tokens, media, box, spacing } from '@tokens'
import { ThemeContext, NavTriggerContext } from '@layout/Layout'
import { useInView } from 'react-intersection-observer'

export const NavTriggerWaypoint = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: ${tokens.z('under')};
  display: block;
  height: 1px;
  /* background-color: red; */
`

export default ({ className, style, isFooter }) => {
  const [hasToggled, setHasToggled] = useState(false)
  const [ref, inView, entry] = useInView({
    threshold: 0,
  })
  const { triggerTheme, setTriggerTheme } = useContext(NavTriggerContext)
  const { theme } = useContext(ThemeContext)

  const handleMenuToggle = (direction) => {
    if (isFooter) {
      if (triggerTheme !== 'dark' && direction === 'down') {
        setTriggerTheme('dark')
      } else if (triggerTheme !== theme && direction === 'up') {
        setTriggerTheme('light')
      }
    } else {
      setTriggerTheme(triggerTheme === 'light' ? 'dark' : 'light')
    }
  }

  useEffect(() => {
    if (!entry) return
    if (!hasToggled && triggerTheme === theme && !isFooter) {
      return
    }

    const {
      boundingClientRect: { y: distanceTop },
    } = entry

    if (distanceTop < 0) {
      handleMenuToggle('down')
      setHasToggled(true)
    }

    if (distanceTop > 0 && hasToggled) {
      handleMenuToggle('up')
      setHasToggled(false)
    }
  }, [inView])

  return <NavTriggerWaypoint ref={ref} style={style} className={className} />
}
