import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import { motion } from 'framer-motion'
import CallToActionLinkText from '@elements/CallToActionLinkText'
import Link from '@elements/Link'

export const CallToActionLink = styled(motion.div)`
  max-width: 18.5rem;
  margin-top: ${spacing(2)};

  .link {
    width: 100%;
  }
`

export default ({ link, className }) => {
  const [isHovered, setIsHovered] = useState(false)
  
  return (
    <CallToActionLink
      className={className}
      whileHover="hovered"
      onHoverStart={() => {
        if (!isHovered) {
          setIsHovered(true)
        }
      }}
      onHoverEnd={() => {
        if (isHovered) {
          setIsHovered(false)
        }
      }}
    >
      <Link link={link} linkStyle="none">
        <CallToActionLinkText linkText={link?.text || link?.data?.title} isHovered={isHovered} />
      </Link>
    </CallToActionLink>
  )
}
