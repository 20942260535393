import React, { useState, useEffect, useLayoutEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.1,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0,
      when: 'afterChildren',
    },
  },
}

const TransitionContainer = ({
  children,
  activePath,
  pageTheme,
  theme,
  setTheme,
  menuTheme,
  triggerTheme,
  setTriggerTheme,
  headingColor,
  setHeadingColor,
  pageHeadingColor,
}) => {
  return (
    <>
      <AnimatePresence
        exitBeforeEnter
        initial={activePath !== '/'}
        onExitComplete={() => {
          if (window !== undefined) {
            window.scrollTo(0, 0)
          }

          if (headingColor !== pageHeadingColor) {
            setHeadingColor(pageHeadingColor)
          }
        }}
      >
        <motion.main
          key={activePath}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {children}
        </motion.main>
      </AnimatePresence>
    </>
  )
}

export default TransitionContainer
