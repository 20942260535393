import styled, { css } from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import {
  BaseType,
  BaseTypeSizes,
  LargeTypeSizes,
  Heading,
  H1Sizes,
  H2Sizes,
  H3Sizes,
  H4Sizes,
  H5Sizes,
  H6Sizes,
} from '@global/styles/Typography'
import { ButtonStyles } from '@mixins/MixinsButton'
import { tokens, spacing, media, box } from '@tokens'

export const GlobalStyle = createGlobalStyle`
  /* RESET */
  body,
  h1, h2, h3, h4, h5, h6,
  blockquote, p, pre,
  dl, dd, ol, ul,
  figure,
  hr,
  fieldset, legend {
    margin:  0;
    padding: 0;
  }

  li > {
    ol, ul {
      margin-bottom: 0;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  fieldset {
    border: 0;
    min-width: 0;
  }

  /* GLOBAL */
  :root {
    --breakpoint: xxs;

    ${media.greaterThan('xs')`
      --breakpoint: xs;
    `}

    ${media.greaterThan('s')`
      --breakpoint: s;
    `}

    ${media.greaterThan('m')`
      --breakpoint: m;
    `}

    ${media.greaterThan('l')`
      --breakpoint: l;
    `}

    ${media.greaterThan('xl')`
      --breakpoint: xl;
    `}
  }

  html {
    ${BaseType};
    box-sizing: border-box;
    color: ${tokens.color('text')};
    font-size: ${tokens.get('type.baseFontSize')};
    line-height: ${tokens.get('type.lineHeight.body')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background: ${tokens.color('nav.background')};

    ${media.greaterThan('xl')`
      font-size: 18px;
    `}

    ${media.greaterThan('xxl')`
      font-size: 20px;
    `}


    * {
      outline-color:  ${tokens.brand('dark')};
      outline-offset: ${spacing(0.5)};

      &, &::before, &::after {
        box-sizing: inherit;
      }
    }

    body:not(.focus-outline) {
      * {
        outline: none;
      }
    }
  }

  ::selection {
    background: ${tokens.brand('light')};
  }

  body {
    ${BaseTypeSizes};
  }

  main,
  article {
    position: relative;
    z-index: ${tokens.z('content')};
  }

  address,
  h1, h2, h3, h4, h5, h6,
  p,
  blockquote, pre,
  dl, ol, ul,
  hr,
  table,
  fieldset {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    color: inherit;
    padding: 0;
    border: 0;
    background: unset;
  }

  /* TODO: Make this implementation better. Componentized heading tags are good but don't play nice with wysiwyg */
  h1 {
    ${Heading};
    ${H1Sizes};
  }

  h2 {
    ${Heading};
    ${H2Sizes};
  }

  h3 {
    ${Heading};
    ${H3Sizes};
  }

  h4 {
    ${Heading};
    ${H4Sizes};
  }

  h5 {
    ${Heading};
    ${H5Sizes};
  }

  h6 {
    ${Heading};
    ${H6Sizes};
  }

  dl, ol, ul {
    margin-left: 1.5rem;
  }

  a {
    color: inherit;
    display: inline-block;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .sack {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(
      to right,
      ${tokens.color('gradient.backgrounds.light.left')},
      ${tokens.color('gradient.backgrounds.light.right')}
    );
  }

  .tl-wrapper {
    > .container {
      min-height: 100vh;
      position: relative;
    }

    &-status {
      &--entered {
        transform: none !important;
      }
    }
  }

  .transition-layer {
    position: fixed;
    top:  0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transform: translate(100%, 0);
    z-index: ${tokens.z('cover')};
  }

  .sack {
    position: relative;
    width: 100%;

    ${'' /* // TODO Do this better */}
    .tl-edges {
      overflow: unset !important
    }
  }

  .tl-wrapper {
    &-status {
      &--entered {
        transform: none !important;
      }
    }

    body.is-locked & {
      height: 100vh;
      overflow: hidden;
    }
  }

  .container > .inner {
    ${box({
      spacing: 'x',
    })}
  }
`

export const ContainerMaxWidth = css`
  max-width: ${tokens.get('maxWidth.wide')};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

export default GlobalStyle
