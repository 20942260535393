import React from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { InputBase } from '@mixins/MixinsForms'
import { tokens, spacing, box, media } from '@tokens'
import FieldLabel from './FieldLabel'
import { useFormContext } from 'react-hook-form'

export const Phone = styled.input`
  ${InputBase}
`

export default ({
  name,
  type,
  label,
  errorMessage,
  isRequired,
  isActive,
  handleFocus,
  handleBlur,
  defaultValue,
  theme,
}) => {
  const {
    register,
    errors: { [name]: errors },
  } = useFormContext()
  const validation = {
    pattern: {
      value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i,
      message: 'Invalid Phone Number',
    },
  }

  if (isRequired) {
    validation.required = errorMessage || 'Your Phone Number is required'
  }

  return (
    <>
      <FieldLabel
        label={label}
        inputID={name}
        isRequired={isRequired}
        type={type}
        isActive={isActive}
      />
      {errors && (
        <span className="error">
          {errors?.types?.required}
          {errors?.types?.pattern}
        </span>
      )}
      <Phone
        name={name}
        type={type}
        defaultValue={defaultValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={register(validation)}
        theme={theme}
      />
    </>
  )
}
