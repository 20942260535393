import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'
import { ThemeContext } from '@layout/Layout'
import Text from './TextField'
import TextArea from './TextAreaField'
import Email from './EmailField'
import Phone from './PhoneField'
import Radio from './RadioField'
import FileUpload from './FileUploadField'
import Select from './SelectField'

export const Field = styled.div`
  grid-column-start: auto;
  grid-column-end: span 1;
  position: relative;
  z-index: ${styledMap('type', {
    select: tokens.z('aboveContent'),
    default: tokens.z('content'),
  })};

  .inner {
    position: relative;

    ${styledMap('type', {
      radio: css`
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
      `,
      default: '',
    })};
  }

  .required {
    color: ${tokens.color('error')};
  }

  .error {
    padding-top: ${spacing()};
    position: absolute;
    top: 100%;
    left: 0;
    color: ${tokens.color('error')};
    font-size: 1rem;
  }
`

export default ({ type, theme: themeOverride, className, ...props }) => {
  const { theme } = useContext(ThemeContext)
  const [isActive, setIsActive] = useState(false)
  const Input = {
    text: Text,
    textarea: TextArea,
    email: Email,
    phone: Phone,
    radio: Radio,
    fileupload: FileUpload,
    select: Select,
  }[type]

  if (!Input) {
    return null
  }

  const handleFocus = () => {
    if (!isActive) {
      setIsActive(true)
    }
  }

  const handleBlur = e => {
    if (!e?.target?.value && isActive) {
      setIsActive(false)
    }
  }

  return (
    <Field type={type} className={className}>
      <div className="inner">
        <Input
          {...props}
          type={type}
          isActive={isActive}
          setIsActive={setIsActive}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          theme={themeOverride || theme}
        />
      </div>
    </Field>
  )
}
