// TODO: Create alias for less verbosity

import DesignSystem from 'design-system-utils'
import palette from '@tokens/palette'
import { easing, duration } from '@tokens/easing'
import { generateMedia, pxToRem } from 'styled-media-query'
import { spacingValues } from '@tokens/spacing'
export { box, spacing } from '@tokens/spacing'

const fontFamily = {
  graphik: '"Graphik", "Helvetica", "Arial", "sans-serif"',
  roslindale: '"RoslindaleDisplayCondensed", "sans-serif"',
}

export const tokens = new DesignSystem(
  {
    type: {
      baseFontSize: '16px',

      fontFamily,
      fontFamilyBase: fontFamily.graphik,
      fontFamilyHeading: fontFamily.roslindale,

      lineHeight: {
        base: 1.5,
        homeHeading: 0.875,
        h1: 0.875,
        h2: 1.1666,
        h3: 1.2,
        h4: 1.25,
        h5: 1.25,
        h6: 1.25,
        blockQuote: 1.2222,
        body: 1.5,
        bodyLarge: 1.5,
        label: 1.25,
        meta: 1.2,
        backgroundText: 0.845,
        list: 1.5,
      },

      fontWeight: {
        light: 300,
        normal: 400,
        medium: 500,
        bold: 700,
      },

      letterSpacing: {
        base: 'normal',
        micro: '0.0125em',
        tiny: '0.02em',
        small: '0.0375em',
        medium: '0.0625em',
        large: '0.125em',
      },
    },

    spacing: {
      ...spacingValues,
    },

    colors: {
      ...palette,
    },

    zIndex: {
      under: -1,
      base: 0,
      content: 1,
      aboveContent: 2,
      overlay: 20,
      flyout: 30,
      header: 35,
      nav: 50,
      navTrigger: 60,
      cover: 100,
    },

    maxWidth: {
      tiny: '30rem', // 480
      narrow: '41rem', // 656
      base: '52rem', // 838
      wide: '63rem', // 1008
      xwide: '85.375rem', // 1366
      vast: '120rem', // 1920
    },

    easing,
    duration,

    transition: {
      base: `${easing.base} ${duration.base}`,
      snappy: `${easing.out} ${duration.fast}`,
      fancy: `${easing.outCubic} ${duration.slow}`,
    },

    border: `1px solid ${palette.colorPalette.border.base}`,
    borderRadius: '0.2rem',

    shadows: {
      beerLabel: '0 2px 4px rgba(0, 0, 0, 0.5)',
    },

    component: {
      header: {
        height: {
          s: '7rem',
          m: '9rem',
          l: '9rem',
        },
      },
    },
    customBreaks: {
      beerHeroHeight: '750px',
    },
    magicNumbers: {
      teaserMobile: '25rem',
    },
    beerTeaserRatio: {
      'small-can': '165%',
      'large-can': '212%',
      'small-bottle': '277%',
      'large-bottle': '335%',
      '12-small-can': '49.1%',
      '4-tall-can': '72.9%',
    },
    beerImageScale: {
      'small-can': 0.468,
      'large-can': 0.586,
      'small-bottle': 0.819,
      'large-bottle': 1,
      'small-can-box': 0.491,
      'large-can-box': 0.729,
      'small-bottle-box': 0.843,
      'large-bottle-box': 0.843, // TBD
    },
    breakpoints: {
      xxs: '0px',
      xs: '340px',
      s: '700px',
      m: '900px',
      l: '1200px',
      xl: '1600px',
      xxl: '2000px',
    },
  },
  {
    fontSizeUnit: 'rem',
  }
)

export const media = generateMedia(pxToRem(tokens.get('breakpoints')))

export default tokens
