import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, box, spacing, media } from '@tokens'
import Logo from '@blocks/Logo'
import Social from '@blocks/Social'
import { motion } from 'framer-motion'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import useEventListener from '@hooks/useEventListener'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useSiteNav } from '@hooks/useSiteNav'
import NavDrawerList from './NavDrawerList'
import NavDrawerListItem from './NavDrawerListItem'

export const NavDrawer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${tokens.z('nav')};
  background: ${tokens.color('nav.background')};
  color: ${tokens.color('nav.text')};

  .nav-logo-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
  }

  .nav-inner {
    ${box({
      spacing: 'top',
    })};
    ${box({
      spacing: 'x',
    })};
    height: 100%;
    overflow: scroll;
  }

  .nav-logo {
    margin: 0;
  }

  .nav-columns {
    ${box({
      spacing: 'bottom',
    })};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
    padding-top: ${spacing(5)};
  }

  .nav-column {
    flex: 0 1 auto;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    ${media.greaterThan('s')`
      flex: 0 1 14rem;
    `}

    + .nav-column {
      margin-left: ${spacing(6)};
    }
  }

  .social-container {
    margin-top: auto;
    padding-top: ${spacing(3)};
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;

    ${media.greaterThan('s')`
      padding-bottom: 0.6rem;
    `}
  }

  .social {
    ${media.lessThan('s')`
      justify-content: center;
    `}
  }
`

export const flattenNav = (items) => {
  return items
    .map(({ wordpress_children, ...item }) => {
      if (wordpress_children) {
        return [
          { ...item, isParent: true },
          ...wordpress_children.map((item) => {
            return { ...item, isSubLink: true }
          }),
        ]
      }

      return item
    })
    .flat()
}

export default ({ isNavOpen, activePath }) => {
  const items = useSiteNav('primary-navigation')
  const Breakpoint = useCallback(useBreakpoint(), [])
  const [isMobile, setIsMobile] = useState(false)
  const columnIndex = items.findIndex(({ cssClasses }) => {
    return cssClasses.includes('new-column')
  })

  const lastIndex = items.findIndex(({ cssClasses }) => {
    return cssClasses.includes('mobile-last')
  })

  // Change order of the menu based on classes in the WP Nav
  let navColumns
  if (isMobile && lastIndex) {
    navColumns = [
      [
        items[0],
        ...items.slice(columnIndex, lastIndex),
        ...items.slice(lastIndex),
        ...items.slice(1, columnIndex),
      ],
    ]
  } else {
    navColumns = [items.slice(0, columnIndex), items.slice(columnIndex)]
  }

  useLayoutEffect(() => {
    if (Breakpoint.max('xs')) {
      setIsMobile(true)
    }
  }, [])

  useEventListener(
    'breakpoint', // event to listen to
    ({ detail: { old: oldBreak, new: newBreak } }) => {
      if (newBreak === 's' && oldBreak === 'm') {
        setIsMobile(true)
      } else if (newBreak === 'm' && oldBreak === 's') {
        setIsMobile(false)
      }
    }
  )

  return (
    <NavDrawer
      initial="hidden"
      animate={isNavOpen ? 'visible' : 'hidden'}
      transition={{ duration: 0.2 }}
      variants={{
        visible: {
          opacity: 1,
          pointerEvents: 'all',
        },
        hidden: {
          opacity: 0,
          pointerEvents: 'none',
        },
      }}
    >
      <ScrollLock key="scroll-lock" isActive={isNavOpen} />
      <TouchScrollable key="touch-scrollable">
        <div className="nav-inner">
          <div key={`nav-logo-container`} className="nav-logo-container">
            <Logo lightLogo className="nav-logo" />
          </div>

          <div key="nav-columns" className="nav-columns">
            {navColumns &&
              navColumns.map((navColumn, columnIndex) => {
                return (
                  <div className="nav-column" key={`column-${columnIndex}`}>
                    {navColumn && (
                      <NavDrawerList
                        key={`list-${columnIndex}`}
                        isNavOpen={isNavOpen}
                      >
                        {navColumn.map((navLink, index) => {
                          return (
                            <NavDrawerListItem
                              key={`item-${columnIndex}-${index}`}
                              isNavOpen={isNavOpen}
                              navLink={navLink}
                              isMobile={isMobile}
                              activePath={activePath}
                            />
                          )
                        })}
                      </NavDrawerList>
                    )}
                    {columnIndex === 0 && (
                      <motion.div
                        className="social-container"
                        animate={isNavOpen ? 'navOpen' : 'init'}
                        variants={{
                          navOpen: {
                            opacity: 1,
                            y: 0,
                            transition: {
                              duration: 0.3,
                              delay: 0.2,
                            },
                          },
                          init: {
                            opacity: 0,
                            y: 20,
                          },
                        }}
                      >
                        <Social key="social" className="social" theme="dark" />
                      </motion.div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </TouchScrollable>
    </NavDrawer>
  )
}
