import { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H2Sizes, H3Sizes, Heading, LargeTypeSizes } from '@styles/Typography'

export const PlaceholderBase = css`
  ${H3Sizes}
  ${Heading}
  text-transform: none;
  color: ${styledMap('theme', {
    dark: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};

  ${media.greaterThan('m')`
    ${H2Sizes}
  `}
`

export const InputBase = css`
  position: relative;
  z-index: ${tokens.z('content')};
  appearance: none;
  background-color: transparent;
  color: ${tokens.color('text')};
  display: block;
  resize: none;
  outline: 0;
  width: 100%;
  height: ${spacing(6)};
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: 0.125rem;
  border-bottom-color: ${styledMap('theme', {
    dark: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};
  -webkit-appearance: none;
  border-radius: 0;
  transition: border-color ${tokens.get('transition.base')};

  ${media.greaterThan('m')`
    height: ${spacing(8)};
  `}

  &:hover,
  &:focus {
    border-color: ${tokens.brand('dark')};
  }

  &:only-child {
    margin-bottom: 0;
  }

  &,
  &::placeholder {
    ${PlaceholderBase}
  }

  &::placeholder {
    opacity: 0.6;
  }

  .error + & {
    border-color: ${tokens.color('error')};
  }
`
