import React, { useState, useRef, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { InputBase } from '@mixins/MixinsForms'
import { tokens, spacing, box, media } from '@tokens'
import { ReactComponent as PaperClipIcon } from '@icons/paperclip.svg'
import FieldLabel from './FieldLabel'

export const FileUploadWrap = styled.div`
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::-webkit-file-upload-button {
      visibility: hidden;
    }
  }

  .file-upload-wrap {
    ${InputBase}
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: ${tokens.z('under')};
    margin-top: ${spacing(-1)};
    height: ${spacing(2)};
    width: ${spacing(2)};
    opacity: 0.6;

    .paperclip_svg__paperclip_svg__path {
      fill: ${styledMap('theme', {
        dark: tokens.color('text', 'light'),
        default: tokens.color('text'),
      })};
    }
  }

  .file-name + .icon {
    opacity: 1;
  }
`

export default ({
  name,
  type,
  label,
  errorMessage,
  isRequired,
  isActive,
  handleFocus,
  handleBlur,
  defaultValue,
  theme,
}) => {
  const {
    register,
    errors: { [name]: errors },
    setValue,
    setError,
    clearError,
  } = useFormContext()
  const [fileName, setFileName] = useState('')
  const validation = {
    type: 'Only file types pdf, jpg, png are permitted',
    size: 'only files less that 2mb can be sent.',
  }

  if (isRequired) {
    validation.required = errorMessage || 'This Field is required'
  }

  const handleChange = (e) => {
    const {
      files: [file],
    } = e.target
    clearError(name)

    if (!file) {
      setError(name, validation)
      return
    }

    const reader = new FileReader(file)
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (
        !['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(
          file.type
        )
      ) {
        setError(name, {
          fileType: 'Only file types pdf, jpg, png are permitted',
        })
      } else if (file.size > 2000000) {
        setError(name, {
          toLarge: 'Only files under 2MB are are permitted',
        })
      } else {
        setFileName(file.name)
        setValue(name, `${file.name}~~${file.type}~~${reader.result}`)
      }
    }
  }

  useEffect(() => {
    register({ name }, validation)
  }, [])

  return (
    <FileUploadWrap theme={theme}>
      <FieldLabel
        label={label}
        inputID={name}
        isRequired={isRequired}
        type={type}
        isActive={isActive}
      />
      {errors &&
        errors.types &&
        Object.values(errors.types).map((error, index) => (
          <span key={index} className="error">
            {error}
          </span>
        ))}
      <span className="file-upload-wrap">
        <input
          id={name}
          name={name}
          type="file"
          defaultValue={defaultValue}
          onFocus={handleFocus}
          onChange={handleChange}
        />

        {fileName && <span className="value">{fileName}</span>}
        <PaperClipIcon className="icon" />
      </span>
    </FileUploadWrap>
  )
}
