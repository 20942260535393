import React, { useEffect, useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import Loader from '@elements/Loader'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'
import { useForm, FormContext, useFormContext } from 'react-hook-form'
import { ThemeContext } from '@layout/Layout'
import { ButtonStyles } from '@mixins/MixinsButton'
import uniqid from 'uniqid'
import Arrow from '@svgs/Arrow'
import Field from '@components/Form/Fields'
import ComponentContent from '@blocks/ComponentContent'

export const Newsletter = styled.div`
  .field {
    max-width: 25rem;

    ${media.greaterThan('s')`
      flex: 1 1 auto;
      max-width: none;
    `}

    .required {
      display: none;
    }
  }

  .form {
    display: block;
    position: relative;
    
    ${media.greaterThan('s')`
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    `}
  }

  .confirmation-message {
    height: ${spacing(6)};
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    ${media.greaterThan('m')`
      height: ${spacing(8)};
    `}
  }

  .form-footer {
    position: relative;
    display: inline-flex;
  }
  
  .loader {
    position: absolute;
    width: 2.5rem;
    left: 100%;
    top: 50%;
    margin-left: 1rem;
    
    ${media.greaterThan('m')`
      margin-top: -1rem;
    `}
  }

  .form-error {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0.5rem;
    color: ${tokens.color('error')};
  }
`

const Button = styled.button`
  ${ButtonStyles}
  margin-top: ${spacing(3)};

  &[disabled] {
    &,
    &:hover {
      opacity: 0.4;
    }
  }

  ${media.greaterThan('s')`
    margin-left: ${spacing(3)};
    margin-top: 0;
  `}
`

export default ({ className, children, theme: themeOverride }) => {
  const { theme } = useContext(ThemeContext)
  const [isLoading, setIsLoading] = useState(false)
  const [hasSubmit, setHasSubmit] = useState(false)
  const [formError, setFormError] = useState('')
  const [fieldName] = useState(uniqid('Newsletter-'))
  const field = {
    label: 'Your fancy email',
    name: fieldName,
    type: 'email',
    isRequired: true,
  }
  const methods = useForm({
    validateCriteriaMode: 'all',
  })
  const { handleSubmit } = methods

  const onSubmit = async (data) => {
    setFormError('')
    setIsLoading(true)

    const [email] = Object.values(data)

    const subscribedResponse = await fetch('https://us-west1-hoyne-brewing-admin-key.cloudfunctions.net/klaviyo-subscribe', {
      method: 'POST',
      'Content-Type': 'application/json',
      body: JSON.stringify({
        email,
        list_id: 'RDfszW'
      })
    })

    const isSubscribed = await subscribedResponse.json()

    if (isSubscribed?.error) {
      setIsLoading(false)
      setFormError('There was an error subscribing your profile. Check the console for more information')
      console.error(isSubscribed?.errors)
      return
    }

    setIsLoading(false)
    setHasSubmit(true)
  }

  return (
    <Newsletter className={className} theme={themeOverride || theme}>
      {children}
      {!hasSubmit ? (
        <FormContext {...methods}>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Field
              className="field"
              {...field}
              theme={themeOverride || theme}
            />
            <div className='form-footer'>
              <Button type="submit" theme={themeOverride || theme} large disabled={isLoading}>
                Send
                <Arrow className="arrow" />
              </Button>
              <Loader className='loader' isLoading={isLoading} size={8} />
            </div>
            {formError && <p className='form-error'>{formError}</p>}
          </form>
        </FormContext>
      ) : (
        <ComponentContent
          className="confirmation-message"
          textContent={{
            selectedFields: ['content'],
            content: 'Thank you! Check your inbox for confirmation!',
          }}
        />
      )}
    </Newsletter>
  )
}
