import { useStaticQuery, graphql } from 'gatsby'
// No variables permitted in static Queries ... YET!

const getAllNav = () => {
  const {
    allWpMenu: { nodes: navigation },
  } = useStaticQuery(
    graphql`
      query MenuQuery {
        allWpMenu {
          nodes {
            slug
            name
            menuItems {
              nodes {
                label
                cssClasses
                title
                target
                menuItemId
                id
                url
                childItems {
                  nodes {
                    label
                    cssClasses
                    title
                    target
                    menuItemId
                    id
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return navigation
}

export const useSiteNav = (slug) => {
  const query = getAllNav()
  return slug ? query.find((nav) => nav.slug === slug)?.menuItems.nodes : query
}

export const useSubNav = (slug) => {
  const query = getAllNav()

  return query
    .find((nav) => nav.slug === slug)
    ?.menuItems.nodes.filter((parent) => {
      return parent.childItems
    })
    .map((parent) => {
      return parent.childItems.nodes.map(({ url }) => {
        return url
      })
    })
    .flat()
}
