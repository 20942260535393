import React, { useEffect, useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'
import { motion } from 'framer-motion'
import { useBreakpoint } from '@hooks/useBreakpoint'
import useEventListener from '@hooks/useEventListener'

export const FieldLabel = styled(motion.label)`
  position: absolute;
  height: ${spacing(6)};
  display: block;
  line-height: 1;
  opacity: 1;
  z-index: ${tokens.z('base')};
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  color: ${styledMap('theme', {
    dark: tokens.color('text.darkFainter'),
    default: tokens.color('text.lightFainter'),
  })};

  ${media.greaterThan('m')`
    height: ${spacing(8)};
  `}

  ${styledMap('type', {
    textarea: css`
      left: ${spacing(2)};
      padding-top: ${spacing(2)};
      align-items: flex-start;
      height: ${spacing(8)};

      ${media.greaterThan('m')`
        height: ${spacing(10)};
      `}
    `,
    default: '',
  })};
`

export const LabelFontSizes = {
  xxs: {
    active: '14px',
    initial: '16px',
  },
  xs: {
    active: '16px',
    initial: '20px',
  },
  s: {
    active: '16px',
    initial: '18px',
  },
  default: {
    active: '20px',
    initial: '24px',
  },
}

export default ({ label, inputID, isRequired, type, isActive, theme }) => {
  const Breakpoint = useCallback(useBreakpoint(), [])
  const [fontSize, setFontSize] = useState(
    LabelFontSizes[
      Breakpoint && Breakpoint.max('s') ? Breakpoint.current : 'default'
    ]
  )

  useEventListener(
    'breakpoint', // event to listen to
    ({ detail: { old: oldBreak, new: newBreak } }) => {
      setFontSize(
        LabelFontSizes[
          Breakpoint && Breakpoint.max('s') ? Breakpoint.current : 'default'
        ]
      )
    }
  )

  return (
    <FieldLabel
      type={type}
      htmlFor={inputID}
      animate={isActive ? 'active' : 'initial'}
      theme={theme}
      style={
        isActive
          ? {
              fontWeight: 400,
              letterSpacing: tokens.get('letterSpacing.tiny'),
            }
          : {
              fontWeight: 900,
              letterSpacing: 0,
            }
      }
      variants={{
        active: {
          fontSize: fontSize.active,
          y: '-60%',
          left: 0,
        },
        initial: {
          fontSize: fontSize.initial,
          y: 0,
          left: type === 'textarea' ? 16 : 0,
        },
      }}
    >
      {label}
      {isRequired && <span className="required">&nbsp;*</span>}
    </FieldLabel>
  )
}
