import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import { easing, duration } from '@tokens/easing'
import { Link as GatsbyLink } from 'gatsby'
import { H1SizesShort, H2Sizes, H3Sizes } from '@styles/Typography'

export const ButtonStyles = css`
  ${H2Sizes}
  position: relative;
  cursor: pointer;
  display: inline-flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 0 ${spacing(2)};
  height: ${spacing(6)};
  color: ${styledMap('theme', {
    dark: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};
  background-color: ${styledMap('theme', {
    dark: tokens.color('button.base.dark.background'),
    default: tokens.color('button.base.light.background'),
  })};
  border: none;
  border-radius: ${spacing()};
  line-height: 1;
  font-weight: ${tokens.get('type.fontWeight.bold')};
  text-decoration: none;
  transition: background-color 0.3s linear, border-color 0.3s linear, color 0.3s;

  ${styledMap('large', {
    true: css`
      ${media.greaterThan('m')`
        ${H1SizesShort}
        height: ${spacing(8)};
        padding-left: ${spacing(3)};
        padding-right: ${spacing(3)};
      `}
    `,
    default: '',
  })};

  &:hover,
  &:focus,
  &:active {
    background-color: ${styledMap('theme', {
      dark: tokens.color('button.base.dark.backgroundHover'),
      default: tokens.color('button.base.light.backgroundHover'),
    })};
  }

  &:last-child {
    margin-right: 0;
  }

  .arrow {
    margin-left: ${spacing(2)};
  }

  .arrow-down {
    margin-left: ${spacing(1)};
    margin-top: ${spacing(0.5)};
  }

  svg {
    fill: ${styledMap('theme', {
      dark: tokens.color('text', 'light'),
      default: tokens.color('text'),
    })};
  }

  &.button--toggle {
    height: ${spacing(5)};
    padding: 0 ${spacing(2)};
    font-weight: ${tokens.get('type.fontWeight.normal')};
    line-height: 1;
    background: transparent;
    color: ${styledMap('theme', {
      dark: tokens.color('text', 'light'),
      default: tokens.color('filter.toggle'),
    })};

    &:hover {
      background-color: ${tokens.color('button.base.light.background')};
    }

    ${media.greaterThan('s')`
      ${H2Sizes}
      height: ${spacing(5)};
      padding: 0 ${spacing(2)};
    `}
  }
`

export const Button = styled(GatsbyLink)`
  ${ButtonStyles};
`

export const ExternalButton = styled.a`
  ${ButtonStyles};
`
