import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H1SizesShort, H2Sizes, BlogH1Sizes } from '@styles/Typography'

export const Heading = styled.h2`
  ${styledMap('headingSize', {
    large: css`
      ${BlogH1Sizes};
      flex: 0 1 ${tokens.get('maxWidth.base')};
      max-width: ${tokens.get('maxWidth.base')};
      margin-bottom: ${spacing(5)};

      &:last-child {
        ${box({
          spacing: 'bottom',
          multiplier: 0.5,
          type: 'margin',
        })};
      }
    `,
    shortLarge: css`
      ${H1SizesShort}
      flex: 0 1 ${tokens.get('maxWidth.narrow')};
      max-width: ${tokens.get('maxWidth.narrow')};
    `,
    default: css`
      ${H2Sizes}
      flex: 0 1 ${tokens.get('maxWidth.narrow')};
      max-width: ${tokens.get('maxWidth.narrow')};
    `,
  })};
`

export default ({
  className = 'heading',
  selectedFields = ['heading'],
  heading,
  headingSize,
  children,
}) => {
  if (children) {
    return (
      <Heading headingSize={headingSize} className={className}>
        {children}
      </Heading>
    )
  }

  if (!heading || !selectedFields.includes('heading')) {
    return null
  }

  return (
    <Heading
      headingSize={headingSize}
      className={className}
      dangerouslySetInnerHTML={{ __html: heading }}
    />
  )
}
