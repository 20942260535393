import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import { ListBase } from '@mixins/MixinsList'
import { motion } from 'framer-motion'

const NavDrawerSubList = styled(motion.ul)`
  ${ListBase}
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export default ({ children, isNavOpen, isMobile, isListOpen }) => {
  let animate

  if (isMobile) {
    animate = isListOpen ? 'open' : 'closed'
  } else {
    animate = isNavOpen ? 'navOpen' : 'init'
  }

  return (
    <NavDrawerSubList
      animate={animate}
      variants={{
        navOpen: {
          opacity: 1,
          transition: {
            delayChildren: 0.1,
            staggerChildren: 0.05,
          },
        },
        closed: {
          height: 0,
          opacity: 0,
          transition: {
            duration: 0.3,
            ease: 'easeOut',
          },
        },
        open: {
          height: 'auto',
          opacity: 1,
          transition: {
            duration: 0.3,
            ease: 'easeOut',
          },
        },
        init: {
          opacity: 0,
        },
      }}
    >
      {children}
    </NavDrawerSubList>
  )
}
