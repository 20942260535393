import React, { useState, useContext, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import { motion } from 'framer-motion'
import CircleLottie from '@svgs/CircleLottie'
import Arrow from '@svgs/Arrow'

export const BackButton = styled(motion.div)`
  position: absolute;
  left: ${spacing(-1)};
  top: 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  cursor: pointer;

  ${media.greaterThan('s')`
    left: 0;
    top: 0;
  `}

  ${media.greaterThan('m')`
    left: ${spacing(-2)};
  `}

  ${media.greaterThan('l')`
    left: 0;
    top: ${spacing(2)};
  `}

  .arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: ${tokens.z('aboveContent')};
    display: block;
    margin-top: ${spacing(-1.25)};
    margin-left: ${spacing(-1.25)};
    height: ${spacing(2.5)};
    width: ${spacing(2.5)};
    opacity: 0.9;
    fill: ${styledMap('theme', {
      dark: tokens.color('text', 'light'),
      light: tokens.color('text'),
    })};
    transform: scaleX(-1);
    transition: fill 300ms linear;

    ${media.greaterThan('s')`
      margin-top: ${spacing(-1.5)};
      margin-left: ${spacing(-1.5)};
      height: ${spacing(3)};
      width: ${spacing(3)};
    `}
  }
`

export default ({ theme }) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleClick = () => {
    if (window !== undefined) {
      window.history.back()
    }
  }
  return (
    <BackButton
      theme={theme}
      onClick={handleClick}
      onHoverStart={() => {
        if (!isHovered) {
          setIsHovered(true)
        }
      }}
      onHoverEnd={() => {
        if (isHovered) {
          setIsHovered(false)
        }
      }}
      initial="initial"
      animate="enter"
      exit="initial"
      variants={{
        initial: {
          opacity: 0,
          x: -10,
        },
        enter: {
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.2,
            when: 'beforeChildren',
          },
        },
        exit: {
          opacity: 0,
          x: -10,
          transition: {
            delay: 0.2,
            duration: 0.2,
            when: 'beforeChildren',
          },
        },
      }}
    >
      <CircleLottie theme={theme} circleIndex={1} isHovered={isHovered} />
      <Arrow className="arrow" theme={theme} />
    </BackButton>
  )
}
