import React, { useState, useLayoutEffect, useEffect } from 'react'
import { spacing } from '@tokens'
import supportsWebP from 'supports-webp'
import { Normalize } from 'styled-normalize'
import GlobalStyle from '@global/styles/Global'
import useEventListener from '@hooks/useEventListener'
import { keys } from '@utils/constants'
import Header from '@regions/Header'
import Footer from '@regions/Footer'
import { useSubNav } from '@hooks/useSiteNav'
import NavTriggerWaypoint from '@elements/NavTriggerWaypoint'
import AgeVerification from '@regions/AgeVerification'
import TransitionContainer from './TransitionContainer'
import Background from './Background'

export const ThemeContext = React.createContext()
export const NavTriggerContext = React.createContext()

const enableOutline = (event) => {
  if (
    event.which === keys.TAB &&
    !document.body.classList.contains('focus-outline')
  ) {
    document.body.classList.add('focus-outline')
  }
}

const Layout = ({ children, location, pageContext }) => {
  const {
    theme: pageTheme = 'light',
    menuTheme: pageMenuTheme,
    headingColor: pageHeadingColor,
    template,
    postType,
    gradient,
  } = pageContext
  const { pathname: path } = location
  const menuTheme = ['home'].includes(template)
    ? 'dark'
    : pageMenuTheme || pageTheme
  const [theme, setTheme] = useState(pageTheme)
  const [headingColor, setHeadingColor] = useState(pageHeadingColor)
  const [triggerTheme, setTriggerTheme] = useState(menuTheme)
  const [supportsWebp, setSupportsWebp] = useState(true)
  const subNavPaths = useSubNav('primary-navigation')

  useEffect(() => {
    if (theme !== pageTheme) {
      setTheme(pageTheme)
    }

    if (triggerTheme !== menuTheme) {
      setTriggerTheme(menuTheme)
    }
  }, [path])

  useEffect(() => {
    supportsWebP.then((supported) => {
      if (!supported) {
        setSupportsWebp(false)
      }
    })
  }, [])

  // TODO Set archive path in CMS
  let navBarActivePath
  if (['beer'].includes(postType) || ['mixerpack'].includes(postType) || ['series'].includes(template)) {
    navBarActivePath = '/beers/'
  } else if (['post'].includes(postType)) {
    navBarActivePath = '/events-stories/'
  } else if (path === '/sponsorship-form/') {
    navBarActivePath = '/contact/'
  } else if (subNavPaths.includes(path)) {
    navBarActivePath = 'isSubPage'
  }

  useEventListener('keydown', (event) => {
    enableOutline(event)
  })

  const [isOfAge, setIsOfAge] =
    typeof window !== 'undefined'
      ? useState(localStorage.getItem('HoyneIsOfAge', 1))
      : [true, true]

  useLayoutEffect(() => {
    const localIsOfAge = localStorage.getItem('HoyneIsOfAge', 1)
    if (!localIsOfAge) {
      setIsOfAge(false)
    }
  }, [])

  return (
    <>
      <Normalize />
      <GlobalStyle />
      <ThemeContext.Provider
        value={{ theme, headingColor, menuTheme, supportsWebp }}
      >
        <NavTriggerContext.Provider value={{ triggerTheme, setTriggerTheme }}>
          <AgeVerification isOfAge={isOfAge} setIsOfAge={setIsOfAge} />
          <Header
            navBarActivePath={navBarActivePath}
            activePath={path}
            theme={triggerTheme}
          />
          <Background
            theme={theme}
            triggerTheme={triggerTheme}
            gradient={gradient}
            postType={postType}
            isOfAge={isOfAge}
          >
            <TransitionContainer
              activePath={path}
              theme={theme}
              setTheme={setTheme}
              menuTheme={menuTheme}
              triggerTheme={triggerTheme}
              setTriggerTheme={setTriggerTheme}
              pageTheme={pageTheme}
              pageHeadingColor={pageHeadingColor}
              headingColor={headingColor}
              setHeadingColor={setHeadingColor}
            >
              {children}
              <NavTriggerWaypoint
                className="nav-waypoint"
                style={{ bottom: spacing(9) }}
                isFooter
              />
            </TransitionContainer>
          </Background>
          <Footer />
        </NavTriggerContext.Provider>
      </ThemeContext.Provider>
      <div id="modal-parent" />
    </>
  )
}

export default Layout
