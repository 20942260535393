import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import { motion } from 'framer-motion'
import { slugify } from '@utils/templates'
import ArrowDown from '@svgs/ArrowDown'
import NavDrawerSubList from './NavDrawerSubList'
import NavDrawerLink from './NavDrawerLink'

const NavDrawerListItem = styled(motion.li)`
  position: relative;
  list-style: none;
  display: block;
  opacity: ${styledMap('isSubLink', {
    true: 0.6,
    default: 1,
  })};
  margin-top: ${spacing(2)};
  padding-top: ${styledMap('isSubLink', {
    true: spacing(0.25),
    default: 0,
  })};

  .nav-line {
    position: absolute;
    bottom: 0;
    left: 0;
    fill: ${tokens.color('text', 'light')};
  }
`

export default ({
  navLink: { childItems, ...navLink },
  isNavOpen,
  isMobile,
  activePath,
}) => {
  const [isListOpen, setListOpen] = useState(false)
  const subLinks = childItems?.nodes
  const handleListToggle = () => {
    setListOpen(!isListOpen)
  }

  return (
    <NavDrawerListItem
      variants={{
        init: {
          opacity: 0,
          y: 20,
        },
        navOpen: {
          opacity: navLink.isSubLink ? 0.6 : 1,
          y: 0,
          transition: {
            duration: 0.3,
          },
        },
      }}
    >
      <NavDrawerLink
        navLink={navLink}
        isActive={activePath === navLink.url}
        isParent={subLinks.length > 0}
        isListOpen={isListOpen}
        handleListToggle={handleListToggle}
        isMobile={isMobile}
      />

      {subLinks && (
        <>
          <NavDrawerSubList
            isNavOpen={isNavOpen}
            isListOpen={isListOpen}
            isMobile={isMobile}
          >
            {subLinks.map((subNavLink, childIndex) => {
              return (
                <NavDrawerListItem
                  key={childIndex}
                  isNavOpen={isNavOpen}
                  isSubLink
                >
                  <NavDrawerLink
                    navLink={subNavLink}
                    isActive={activePath === subNavLink.url}
                    isSubLink
                  />
                </NavDrawerListItem>
              )
            })}
          </NavDrawerSubList>
        </>
      )}
    </NavDrawerListItem>
  )
}
