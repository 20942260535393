import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import {
  H1SizesShort,
  H2Sizes,
  H3Sizes,
  H4Sizes,
  H5Sizes,
  Heading,
} from '@styles/Typography'
import Link from '@elements/Link'
import Arrow from '@svgs/Arrow'
import NavLine from '@svgs/NavLine'
import ArrowDown from '@svgs/ArrowDown'
import { motion } from 'framer-motion'

const NavDrawerLink = styled(motion.span)`
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: ${styledMap('isSubLink', {
    true: spacing(3),
    default: spacing(5),
  })};

  ${media.greaterThan('s')`
    height: ${spacing(6)};
  `}

  a {
    position: relative;
    color: ${tokens.color('nav.text')};
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: ${spacing(1)};
    margin-bottom: ${spacing(-1)};

    ${styledMap('isSubLink', {
      true: css`
        ${H3Sizes}

        ${media.greaterThan('s')`
          ${H2Sizes}
        `}
      `,
      default: css`
        ${H1SizesShort}
        ${Heading}
        text-transform: none;
      `,
    })};
  }

  .nav-link.is-active {
    cursor: default;
  }

  .arrow {
    fill: ${tokens.color('nav.text')};
    margin-left: ${spacing(1.5)};
    transform: rotate(-45deg);
    width: ${spacing(3)};
    height: ${spacing(3)};
  }

  .nav-line {
    position: absolute;
    left: 0;
    width: 100%;
    fill: ${tokens.color('text', 'light')};
  }

  .arrow-down {
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: ${spacing(0.5)};
    width: ${spacing(4)};
    height: ${spacing(5)};
    margin-top: ${spacing(0.25)};
    z-index: ${tokens.z('aboveContent')};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      margin-top: 0.75rem;
      margin-bottom: 0.5rem;
      width: ${spacing(2.5)};
      fill: ${tokens.color('text', 'light')};
    }
  }
`

export default ({
  navLink: { label, cssClasses = [], url, target },
  isMobile,
  isSubLink,
  isActive,
  isListOpen,
  isParent,
  handleListToggle,
}) => {
  const className = [
    ...cssClasses,
    isSubLink ? 'sub-nav-link' : 'nav-link',
    isActive ? 'is-active' : '',
  ].join(' ')

  const [isHovered, setIsHovered] = useState(false)
  return (
    <NavDrawerLink
      isSubLink={isSubLink}
      whileHover="hovered"
      onHoverStart={() => {
        if (!isHovered) {
          setIsHovered(true)
        }
      }}
      onHoverEnd={() => {
        if (isHovered) {
          setIsHovered(false)
        }
      }}
    >
      <Link
        link={{
          type: 'url',
          target,
          url,
        }}
        className={className}
        linkStyle="none"
      >
        {label}
        {cssClasses.includes('has-arrow') && <Arrow className="arrow" />}
        <NavLine
          className="nav-line"
          isActive={isActive}
          isHovered={isHovered}
        />
      </Link>
      {isParent && isMobile && (
        <button
          type="button"
          className="arrow-down"
          onClick={() => handleListToggle()}
        >
          <ArrowDown direction={isListOpen ? 'up' : 'down'} />
        </button>
      )}
    </NavDrawerLink>
  )
}
