import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, box, spacing, media } from '@tokens'
import lottie from 'lottie-web'
import { motion } from 'framer-motion'
import * as circle1 from '@lottie/circle1.json'
import * as circle2 from '@lottie/circle2.json'
import * as circle3 from '@lottie/circle3.json'
import { ThemeContext } from '@layout/Layout'

const Animation = styled(motion.div)`
  width: ${spacing(7.5)};
  height: ${spacing(7.5)};
  transition: filter 300ms linear;
  ${styledMap('theme', {
    dark: css`
      filter: invert();
    `,
    default: '',
  })};

  ${media.greaterThan('m')`
    width: ${spacing(8.5)};
    height: ${spacing(8.5)};
  `}
`

const CircleLottie = ({
  isHovered,
  theme: themeOverride,
  className,
  circleIndex,
}) => {
  const circle = [circle1, circle2, circle3][
    Number.isInteger(circleIndex)
      ? circleIndex
      : Math.floor(Math.random() * Math.floor(3))
  ]
  const { theme } = useContext(ThemeContext)
  const [isStopped, setIsStopped] = useState(true)
  const [animationState, setAnimationState] = useState(null)
  const { default: animationData } = circle
  const ref = useRef(null)

  const handlePlay = () => {
    animationState.goToAndPlay(0, true)
    setIsStopped(false)
  }

  useLayoutEffect(() => {
    const animation = lottie.loadAnimation({
      container: ref.current,
      animationData,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      rendererSettings: {
        progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
      },
    })
    animation.goToAndStop(animationData.op, true)
    setAnimationState(animation)

    return () => {
      animation.destroy()
    }
  }, [])

  if (animationState && isHovered && isStopped) {
    handlePlay()
  } else if (animationState && !isHovered && !isStopped) {
    setIsStopped(true)
  }

  return (
    <Animation
      theme={themeOverride || theme}
      ref={ref}
      className={className}
      isHovered={isHovered}
    />
  )
}

export default CircleLottie
