import React, { useContext } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import ArrowDual from '@svgs/ArrowDual'
import ArrowDualCircle from '@svgs/ArrowDualCircle'
import { ButtonStyles } from '@mixins/MixinsButton'
import CircleLottie from '@svgs/CircleLottie'
import { AnimatePresence, motion } from 'framer-motion'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import useEventListener from '@hooks/useEventListener'
import { keys } from '@utils/constants'
import { ThemeContext } from '@layout/Layout'
import { ReactComponent as IconClose } from '@icons/close.svg'
import { hamburgerPaths } from '@regions/NavDrawer/NavDrawerTrigger'

export const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: ${tokens.z('cover')};
  overflow: hidden;
`

export const Modal = styled(motion.div)`
  position: relative;
  width: 100%;
  max-width: ${styledMap('isSlider', {
    true: 'none',
    default: (props) => tokens.get(`maxWidth.${props.size}`),
  })};
  z-index: ${tokens.z('cover')};
  max-height: 100%;

  > .inner {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image,
    div {
      height: 100%;
      display: block;
    }

    .gatsby-image-wrapper {
      > div {
        /* overriding gatsby-image */
        padding-bottom: 0 !important;
        height: 100%;
      }
    }
  }
`

export const ModalLink = styled(motion.button)`
  ${styledMap('isGallery', {
    true: css`
      color: ${styledMap('theme', {
        dark: tokens.color('text', 'light'),
        default: tokens.color('text'),
      })};
      position: relative;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      z-index: ${tokens.z('aboveContent')};
      margin-left: auto;
      margin-right: auto;
      background: transparent;
      border: none;
      padding: 0;
      opacity: 0.7;
    `,
    default: ButtonStyles,
  })};

  .dual-arrow-circle {
    margin-bottom: ${spacing()};
  }

  .circle-arrows {
    fill: ${styledMap('theme', {
      dark: tokens.color('text', 'light'),
      default: tokens.color('text'),
    })};
  }
`

export const ModalControls = styled(motion.div)`
  max-width: ${(props) => tokens.get(`maxWidth.${props.size}`)};
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  z-index: ${tokens.z('cover') + 1};
  margin: 0 auto;
  pointer-events: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  height: ${spacing(6)};

  button {
    pointer-events: all;
  }

  .modal-close {
    position: fixed;
    top: ${spacing()};
    right: ${spacing()};
    border: none;
    cursor: pointer;

    ${media.greaterThan('s')`
      top: ${spacing(2)};
      right: ${spacing(2)};
    `}

    ${media.greaterThan('l')`
      top: ${spacing(4)};
      right: ${spacing(4)};
    `}
  }

  .close-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin-top: ${spacing(-1.375)};
    margin-left: ${spacing(-1.375)};
    height: ${spacing(2.75)};
    width: ${spacing(2.75)};
    opacity: 0.9;

    ${media.greaterThan('s')`
      margin-top: ${spacing(-1.75)};
      margin-left: ${spacing(-1.75)};
      height: ${spacing(3.5)};
      width: ${spacing(3.5)};
    `}

    path {
      fill: rgb(242, 240, 234);
    }
  }
`

export const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: ${tokens.z('cover') - 1};
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  background: ${tokens.color('black', '09')};
  transform: translate3d(-50%, -50%, 0);
`

export default ({
  className,
  children,
  buttonLabel = 'Learn More',
  isGallery,
  theme: themeOverride,
  size,
  isLink,
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const { theme } = useContext(ThemeContext)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  useEventListener(
    'keydown', // event to listen to
    (event) => {
      if (keys.ESC === event.keyCode) {
        closeModal()
      }
    }
  )

  return (
    <>
      <ModalLink
        className={className}
        isGallery={isGallery}
        type="button"
        onClick={openModal}
        theme={themeOverride || theme}
      >
        {isGallery ? (
          <>
            {!isLink && (
              <ArrowDualCircle
                className="arrow dual-arrow-circle"
                theme={theme}
              />
            )}
            <span className='modal-link-text'>{buttonLabel}</span>
            
          </>
        ) : (
          <>
            {buttonLabel}
            <ArrowDual className="arrow" theme={theme} />
          </>
        )}
      </ModalLink>
      <AnimatePresence>
        {modalIsOpen && (
          <>
            {createPortal(
              <ModalContainer>
                <Overlay
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1 }}
                  onClick={closeModal}
                />
                <Modal
                  className={`${className} modal`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2, delay: 0.1 }}
                  size={size}
                >
                  <ScrollLock />
                  <TouchScrollable>
                    <div className="inner">{children}</div>
                  </TouchScrollable>
                </Modal>
                <ModalControls
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2, delay: 0.1 }}
                >
                  <button
                    className="modal-close"
                    type="button"
                    onClick={closeModal}
                  >
                    <CircleLottie
                      className="circle"
                      theme="dark"
                      circleIndex={0}
                    />
                    <svg
                      className="close-icon"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 28 28"
                    >
                      {hamburgerPaths.map((path, index) => {
                        return <path d={path.open} />
                      })}
                    </svg>
                    {/* <IconClose className="close-icon" /> */}
                  </button>
                </ModalControls>
              </ModalContainer>,
              document.getElementById('modal-parent')
            )}
          </>
        )}
      </AnimatePresence>
    </>
  )
}
