import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing } from '@tokens'
import { PageContentRTE } from '@mixins/MixinsPage'

/* TODO: Make this implementation better. Componentized heading tags are good but don't play nice with wysiwyg */
export const Heading = css`
  font-weight: ${tokens.get('type.fontWeight.bold')};
  text-transform: ${styledMap('textCase', {
    none: 'none',
    lower: 'lowercase',
    default: 'uppercase',
  })};
`

export const TextColor = css`
  color: ${styledMap('theme', {
    dark: tokens.color('text', 'light'),
    default: tokens.color('text'),
  })};
`

export const FontStyleH1 = css`
  font-family: ${tokens.get('type.fontFamilyHeading')};
  font-weight: ${tokens.get('type.fontWeight.bold')};
  text-transform: ${styledMap('textCase', {
    none: 'none',
    lower: 'lowercase',
    default: 'uppercase',
  })};
`

export const HomeH1Sizes = css`
  line-height: 0.875;
  letter-spacing: 0.01em;
  font-family: ${tokens.get('type.fontFamilyHeading')};
  font-size: 4rem;

  ${media.lessThan('360px')`
    font-size: 3.25rem;
  `}

  ${media.greaterThan('s')`
    font-size: 6rem;
  `}
`

export const BlogH1Sizes = css`
  line-height: 0.875;
  letter-spacing: 0.01em;
  font-family: ${tokens.get('type.fontFamilyHeading')};
  font-size: 3rem;

  ${media.lessThan('360px')`
    font-size: 2.5rem;
  `}


  ${media.greaterThan('s')`
    font-size: 3.5rem;
  `}

  ${media.greaterThan('m')`
    font-size: 5rem;
  `}

  ${media.greaterThan('l')`
    font-size: 6rem;
  `}
`

export const BlogH2Sizes = css`
  font-family: ${tokens.get('type.fontFamilyHeading')};
  font-size: 2.5rem;
  line-height: 0.9;
  letter-spacing: 0.008em;

  ${media.greaterThan('s')`
    font-size: 3rem;
  `}

  ${media.greaterThan('m')`
    font-size: 3.5rem;
  `}
`

export const H1Sizes = css`
  line-height: ${tokens.get('type.lineHeight.h1')};
  font-size: 3.5rem;

  ${media.greaterThan('s')`
    font-size: 6rem;
  `}

  ${media.greaterThan('m')`
    font-size: 6.5rem;

    @media (max-height: ${tokens.get('customBreaks.beerHeroHeight')}) {
      font-size: 5.75rem;
    }
  `}

  ${media.greaterThan('l')`
    font-size: 7rem;

    @media (max-height: ${tokens.get('customBreaks.beerHeroHeight')}) {
      font-size: 5.75rem;
    }
  `}
`

export const H1SizesShort = css`
  line-height: ${tokens.get('type.lineHeight.h2')};
  font-size: 1.375rem;

  ${media.greaterThan('s')`
    font-size: 1.5rem;
  `}
`

export const H2Sizes = css`
  font-size: 1.125rem;
  line-height: ${tokens.get('type.lineHeight.h2')};

  ${media.greaterThan('s')`
    font-size: 1.25rem;
  `}
`

export const H3Sizes = css`
  font-size: 1.125rem;
  line-height: ${tokens.get('type.lineHeight.h3')};

  ${media.greaterThan('s')`
    font-size: 1.25rem;
  `}
`

export const H4Sizes = css`
  font-size: 0.875rem;
  line-height: ${tokens.get('type.lineHeight.h4')};
`

export const H5Sizes = css`
  ${H4Sizes}
`
export const H6Sizes = css`
  ${H4Sizes}
`

export const BaseType = css`
  font-family: ${tokens.get('type.fontFamilyBase')};
  letter-spacing: -${tokens.get('type.letterSpacing.mirco')};
  line-height: ${tokens.get('type.lineHeight.base')};
  text-transform: none;
`

export const BaseTypeSizes = css`
  font-size: 1.125rem;
  font-weight: ${tokens.get('type.fontWeight.normal')};
  line-height: ${styledMap('lineHeight', {
    tall: tokens.get('type.lineHeight.list'),
    default: tokens.get('type.lineHeight.body'),
  })};

  ${media.greaterThan('s')`
    font-size: 1.25rem;
  `}
`

export const LargeTypeSizes = css`
  font-size: 1.125rem;
  line-height: ${tokens.get('type.lineHeight.bodyLarge')};
  letter-spacing: -${tokens.get('type.letterSpacing.tiny')};

  ${media.greaterThan('s')`
    font-size: 1.5rem;
  `}
`

export const LargeBoldHeadingSizes = css`
  font-size: 1.75rem;

  ${media.greaterThan('s')`
    font-size: 2rem;
  `}

  ${media.greaterThan('m')`
    font-size: 2.25rem;
  `}
`

export const BlockQuoteSizes = css`
  font-size: 2rem;
  line-height: ${tokens.get('type.lineHeight.body')};

  ${media.greaterThan('s')`
    font-size: 4rem;
  `}

  ${media.greaterThan('l')`
    font-size: 4.5rem;
  `}
`

export const H1 = styled.h1`
  ${FontStyleH1};
  ${H1Sizes};
`
export const H2 = styled.h2`
  ${Heading};
  ${H2Sizes};
`

export const H3 = styled.h3`
  ${Heading};
  ${H3Sizes};
`

export const H4 = styled.h4`
  ${Heading};
  ${H4Sizes};
`

export const H5 = styled.h5`
  ${Heading};
  ${H5Sizes};
`

export const H6 = styled.h6`
  ${Heading};
  ${H6Sizes};
`

export const PIntro = styled.p`
  ${LargeTypeSizes}
`

export const HugeTypeStyles = css`
  font-size: 2.5rem;
  font-weight: ${tokens.get('type.fontWeight.medium')};
  line-height: 1.1;
  letter-spacing: 0.0138em;

  ${media.greaterThan('s')`
    font-size: 3rem;
  `}

  ${media.greaterThan('1000px')`
    font-size: 4rem;
  `}

  ${media.greaterThan('l')`
    font-size: 4.5rem;
  `}
`

export const LabelStyles = css`
  font-size: 0.875rem;
  font-weight: ${tokens.get('type.fontWeight.medium')};
  line-height: ${tokens.get('type.lineHeight.label')};
  text-transform: uppercase;

  ${media.greaterThan('s')`
    font-size: 1rem;
  `}
`

export const LinkTextStyles = css`
  font-weight: ${tokens.get('type.fontWeight.bold')};
  text-transform: none;
`

export const MetaStyles = css`
  letter-spacing: -${tokens.get('type.letterSpacing.micro')};
  line-height: ${tokens.get('type.lineHeight.meta')};
  font-size: 0.75rem;

  ${media.greaterThan('s')`
    font-size: 1rem;
  `}

  ${media.greaterThan('m')`
    font-size: 1.25rem;
  `}
`

export const Label = styled.span`
  ${LabelStyles}
`

export const Meta = styled.div`
  ${MetaStyles}
`

export const HomeHeroHeading = styled.h1`
  ${H1Sizes}
`
