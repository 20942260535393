import React from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { spacing } from '@tokens'
import ArrowCircleLottie from '@svgs/ArrowCircleLottie'
import Line from '@svgs/Line'

const SvgContainer = styled.span`
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  .line {
    margin-right: ${spacing(1)};
  }
`

const ArrowCircleLine = ({ className = '', isHovered, theme }) => {
  return (
    <SvgContainer className={className}>
      <Line className="line" isHovered={isHovered} theme={theme} />
      <ArrowCircleLottie isHovered={isHovered} theme={theme} />
    </SvgContainer>
  )
}

export default ArrowCircleLine
