import { useStaticQuery, graphql } from 'gatsby'
// No variables permitted in static Queries ... YET!
export const useSocial = () => {
  const {
    wp: {
      companyDetails: {
        contact: { social },
      },
    },
  } = useStaticQuery(
    graphql`
      query MyQuery {
        wp {
          companyDetails {
            contact {
              social {
                facebook
                instagram
                twitter
              }
            }
          }
        }
      }
    `
  )

  return social || {}
}
