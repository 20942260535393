import React from 'react'
import { motion } from 'framer-motion'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { tokens, media, box, spacing } from '@tokens'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import { ButtonStyles } from '@mixins/MixinsButton'
import { BlogH2Sizes } from '@styles/Typography'
import ComponentContainer from '@layout/ComponentContainer'
import Logo from '@blocks/Logo'
import Heading from '@elements/Heading'
import TextContent from '@elements/TextContent'
import Arrow from '@svgs/Arrow'
import NavLine from '@svgs/NavLine'
import Wheat from '@images/illustrations/wheat.png'
import { TextUnderLine } from '@mixins/MixinsPage'
import Modal from '@components/Modal'

export const AgeVerification = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: ${tokens.z('cover') - 1};
  background: ${tokens.color('nav.background')};
  color: ${tokens.color('nav.text')};
  overflow: scroll;

  .viewport {
    ${box({
      spacing: 'top',
    })};
    ${box({
      spacing: 'bottom',
      multiplier: 0.5,
    })};
    min-height: 100%;
    width: 100%;
  }

  .logo-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  .inner,
  .heading-container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  .heading-container {
    flex: 1 1 100%;
  }

  .heading {
    ${BlogH2Sizes}
    position: relative;
    max-width: 8.666em;
    margin-bottom: ${spacing(5)};
    color: ${tokens.brand('light')};
    text-align: center;
  }

  .cookie-notification {
    margin-top: ${spacing(5)};
    flex: 1 1 100%;
    color: ${tokens.color('text', 'darkFainter')};

    span {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 14em;
      text-align: center;
      font-size: 0.875rem;
    }

    button {
      ${TextUnderLine}
      color: ${tokens.color('text', 'darkFainter')};
      display: inline;
      cursor: pointer;
      transition: color 0.3s ease;
    }
  }

  .underline {
    display: inline-block;
    position: relative;
  }

  .line {
    position: absolute;
    left: 0;
    width: 100%;
    fill: ${tokens.color('text', 'light')};
  }

  .background-image {
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${tokens.z('under')};
    transform: translate3d(-45%, -20%, 0);
    max-width: 10rem;

    ${media.greaterThan('s')`
      max-width: 15rem;
      transform: translate3d(-50%, -50%, 0);
    `}
  }
`

const Button = styled.button`
  ${ButtonStyles}
  ${media.greaterThan('s')`
    margin-left: auto;
    margin-right: 0;
  `}
`

export default ({ isOfAge, setIsOfAge }) => {
  const handleClick = () => {
    localStorage.setItem('HoyneIsOfAge', true)
    setIsOfAge(true)
  }

  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        delay: 0,
        duration: 0,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.3,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  }

  return (
    <AgeVerification
      key="age-verification"
      variants={variants}
      initial="initial"
      animate={!isOfAge ? 'enter' : 'exit'}
    >
      <ScrollLock key="scroll-lock" isActive={!isOfAge} />
      <TouchScrollable key="touch-scrollable">
        <div className="viewport">
          <div className="logo-container">
            <Logo lightLogo className="logo" />
          </div>
          <ComponentContainer size="base" isFlex collapse>
            <div className="heading-container">
              <Heading headingSize="large">
                Are you a lover&nbsp;of the{' '}
                <span className="underline">
                  Finest
                  <NavLine className="line" isActive delay={0.3} />
                </span>
                &nbsp;Beer?
                <img
                  className="background-image"
                  src={Wheat}
                  alt="Illustration of Wheat"
                />
              </Heading>
            </div>
            <Button theme="dark" onClick={handleClick}>
              Yes! And I'm legal, too
              <Arrow className="arrow" />
            </Button>

            <div className="cookie-notification">
              <span>
                By entering this site you agree to our{' '}
                <Modal
                  className="modal-link"
                  buttonLabel="use of cookies"
                  size="wide"
                  theme="dark"
                  isGallery
                  isLink
                >
                  <StaticQuery
                    query={graphql`
                      query CookiePolicy {
                        wp {
                          themeDefaults {
                            contentThemeDefaults {
                              cookiePolicy
                            }
                          }
                        }
                      }
                    `}
                    render={({
                      wp: {
                        themeDefaults: {
                          contentThemeDefaults: { cookiePolicy },
                        },
                      },
                    }) => {
                      return (
                        <ComponentContainer collapse>
                          <TextContent theme="dark">
                            <div
                              style={{
                                color: tokens.color('text', 'light'),
                                paddingTop: spacing(10),
                                paddingBottom: spacing(10),
                                height: 'auto',
                              }}
                              dangerouslySetInnerHTML={{
                                __html: cookiePolicy,
                              }}
                            />
                          </TextContent>
                        </ComponentContainer>
                      )
                    }}
                  />
                </Modal>
              </span>
            </div>
          </ComponentContainer>
        </div>
      </TouchScrollable>
    </AgeVerification>
  )
}
