import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import Arrow from '@svgs/Arrow'
import CircleLottie from '@svgs/CircleLottie'
import { ThemeContext } from '@layout/Layout'
import styledMap from 'styled-map'
import { tokens, box, spacing, media } from '@tokens'
import { motion } from 'framer-motion'

export const SvgContainer = styled(motion.span)`
  display: block;
  position: relative;

  .arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`

const ArrowCircleLottie = ({
  className = '',
  isHovered,
  theme: themeOverride,
}) => {
  const { theme } = useContext(ThemeContext)
  const variant = {
    hovered: {
      x: spacing(3),
      transition: {
        duration: 0.2,
        ease: 'easeOut',
      },
    },
    init: {
      x: 0,
    },
  }
  return (
    <SvgContainer
      className={className}
      animate={isHovered ? 'hovered' : 'init'}
      variants={variant}
    >
      <Arrow className="arrow" theme={themeOverride || theme} />
      <CircleLottie isHovered={isHovered} theme={themeOverride || theme} />
    </SvgContainer>
  )
}

export default ArrowCircleLottie
