import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import { useSiteNav } from '@hooks/useSiteNav'
import NavBarItem from './NavBarItem'

export const NavBar = styled.ul`
  display: none;

  ${media.greaterThan('l')`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
  `}
`

export default ({ className, activePath, theme }) => {
  const links = useSiteNav('feature-navigation')
  return (
    <NavBar className={className}>
      {links &&
        links.map((link) => {
          const isActive = link.url === activePath
          return (
            <NavBarItem
              link={link}
              key={link.id}
              theme={theme}
              isActive={isActive}
            />
          )
        })}
    </NavBar>
  )
}
