import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'
import { ListBase } from '@mixins/MixinsList'
import { motion } from 'framer-motion'

const NavDrawerList = styled(motion.ul)`
  ${ListBase}
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export default ({ children, isNavOpen }) => {
  return (
    <NavDrawerList
      animate={isNavOpen ? 'navOpen' : 'init'}
      variants={{
        navOpen: {
          transition: {
            delayChildren: 0.1,
            staggerChildren: 0.05,
          },
        },
        init: {},
      }}
    >
      {children}
    </NavDrawerList>
  )
}
