import React, { useLayoutEffect, useEffect, useState } from 'react'
import styled from 'styled-components'
import styledMap from 'styled-map'
import Social from '@blocks/Social'
import { MetaStyles } from '@styles/Typography'
import { ListBase } from '@mixins/MixinsList'
import { useSiteNav } from '@hooks/useSiteNav'
import { tokens, media, box, spacing } from '@tokens'
import Newsletter from '@elements/Newsletter'
import NavDrawerLink from '@regions/NavDrawer/NavDrawerLink'

export const Footer = styled.footer`
  ${box()};
  background: ${tokens.color('footer.background')};
  color: ${tokens.color('text', 'light')};
  width: 100%;
  left: 0;
  z-index: ${tokens.z('base')};
  position: relative;

  .footer-container__inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: ${tokens.get('maxWidth.wide')};
    margin-left: auto;
    margin-right: auto;
  }

  .footer-nav {
    ${box({
      spacing: 'bottom',
      multiplier: 0.5,
      type: 'margin',
    })};
  }

  .footer-column {
    flex: 1 1 50%;
  }

  .footer-nav {
    flex: 0 1 23em;
  }

  .footer-newsletter {
    flex: 0 1 36rem;
    max-width: 36rem;
    width: 100%;

    ${media.greaterThan('s')`
      margin-right: ${spacing(3)};
    `}
  }

  .footer-nav__heading,
  .footer-nav__link,
  .company,
  .credit,
  .credit a {
    color: ${tokens.color('footer.text')};
  }

  .footer-nav__heading {
    width: 100%;
    margin-bottom: ${spacing(3)};

    ${media.greaterThan('s')`
      margin-bottom: ${spacing(5)};
    `}
  }

  .footer-nav__columns {
    margin-bottom: ${spacing(-2)};

    ${media.greaterThan('360px')`
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;

    `}
  }

  .footer-nav__column {
    ${ListBase}
    flex: 0 1 auto;
    margin-bottom: ${spacing(2)};
    margin-right: ${spacing(8)};

    &:last-child {
      margin-right: 0;
      margin-top: ${spacing(2)};

      ${media.greaterThan('360px')`
        margin-top: 0;
      `}
    }
  }

  .footer-nav__item {
    margin-top: ${spacing(2)};

    &:first-child {
      margin-top: 0;
    }

    > span {
      height: auto;
    }
  }

  .company,
  .credit,
  .footer-nav__link {
    display: block;
    opacity: 0.6;
  }

  .footer-nav__link {
    font-size: 1.125rem;

    ${media.greaterThan('s')`
    font-size: 1.25rem;
  `}
  }

  .footer-newsletter-heading {
    margin-bottom: ${spacing(3)};
    max-width: 24em;
    opacity: 0.6;
    font-style: italic;
    line-height: ${tokens.get('type.lineHeight.base')};

    ${media.greaterThan('s')`
      margin-bottom: ${spacing(6)};
    `}
  }

  .footer__social {
    margin-bottom: ${spacing(6)};
    justify-content: center;
  }

  .fine-print {
    ${box({
      spacing: 'top',
      multiplier: 0.5,
      type: 'padding',
    })};
    margin-top: auto;
    margin-bottom: 0;
    flex: 1 1 100%;

    ${media.greaterThan('1023px')`
      text-align: right;
      flex: 0 0 auto;
    `}
  }

  .company {
    display: block;
    font-size: 0.875rem;
    line-height: ${tokens.get('type.lineHeight.meta')};
    letter-spacing: -${tokens.get('type.letterSpacing.mirco')};

    ${media.greaterThan('s')`
      font-size: 1rem;
    `}
  }

  .credit {
    font-size: 0.875rem;

    ${media.greaterThan('s')`
      font-size: 1rem;
    `}
  }
`

export default ({ companyName = 'Hoyne Brewing Co.' }) => {
  const items = useSiteNav('footer-navigation')

  const columnIndex = items.findIndex(({ cssClasses }) => {
    return cssClasses.includes('new-column')
  })

  const navColumns = columnIndex
    ? [items.slice(0, columnIndex), items.slice(columnIndex)]
    : [items]

  const year = new Date().getFullYear()

  return (
    <Footer>
      <div className="footer-container">
        <div className="footer-container__inner">
          <div className="footer-nav">
            <h3 className="footer-nav__heading">{companyName}</h3>
            <div className="footer-nav__columns">
              {navColumns &&
                navColumns.map((column, columnIndex) => {
                  return (
                    <ul className="footer-nav__column" key={columnIndex}>
                      {column &&
                        column.map(({ url, label }, index) => {
                          return (
                            <li className="footer-nav__item" key={index}>
                              <NavDrawerLink
                                navLink={{
                                  label,
                                  cssClasses: ['footer-nav__link'],
                                  url,
                                }}
                                isSubLink
                              />
                            </li>
                          )
                        })}
                    </ul>
                  )
                })}
            </div>
          </div>
          <Social className="footer__social" layout="horz" theme="dark" />
          <Newsletter theme="dark" className="footer-newsletter">
            <p className="footer-newsletter-heading">
              We’d love to stay in touch. Sign up for all the latest Hoyne
              happenings here:
            </p>
          </Newsletter>
          <div className="fine-print">
            <span className="company">
              &copy;
              {companyName}
              &nbsp;
              {year}
            </span>
            <span className="credit">
              Site by&nbsp;
              <a
                href="https://www.gearboxbuilt.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gearbox
              </a>
              &nbsp;&amp;&nbsp;
              <a
                href="https://sometimes.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sometimes
              </a>
            </span>
          </div>
        </div>
      </div>
    </Footer>
  )
}
